html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: FontAwesome;
  src: url("../assets/webfonts/font-awesome/fontawesome-webfont.eot");
  src: url("../assets/webfonts/font-awesome/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("../assets/webfonts/font-awesome/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("../assets/webfonts/font-awesome/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("../assets/webfonts/font-awesome/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("../assets/webfonts/font-awesome/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa:before {
  font: 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.fa-lg {
  vertical-align: -15%;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  text-align: center;
  width: 1.28571em;
}

.fa-ul {
  margin-left: 2.14286em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  width: 2.14286em;
  position: absolute;
  top: .142857em;
  left: -2.14286em;
}

.fa-li.fa-lg {
  left: -1.85714em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right {
  margin-left: .3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: .3em;
}

.fa.pull-right {
  margin-left: .3em;
}

.fa-spin {
  -webkit-animation: 2s linear infinite fa-spin;
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  -webkit-animation: 1s steps(8, end) infinite fa-spin;
  animation: 1s steps(8, end) infinite fa-spin;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  vertical-align: middle;
  width: 2em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-envelope-o:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-o:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-remove:before, .fa-close:before, .fa-times:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-trash-o:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-file-o:before {
  content: "";
}

.fa-clock-o:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-arrow-circle-o-down:before {
  content: "";
}

.fa-arrow-circle-o-up:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-play-circle-o:before {
  content: "";
}

.fa-rotate-right:before, .fa-repeat:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-dedent:before, .fa-outdent:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-photo:before, .fa-image:before, .fa-picture-o:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-edit:before, .fa-pencil-square-o:before {
  content: "";
}

.fa-share-square-o:before {
  content: "";
}

.fa-check-square-o:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-times-circle-o:before {
  content: "";
}

.fa-check-circle-o:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-mail-forward:before, .fa-share:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-warning:before, .fa-exclamation-triangle:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-bar-chart-o:before, .fa-bar-chart:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-thumbs-o-up:before {
  content: "";
}

.fa-thumbs-o-down:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-heart-o:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-linkedin-square:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-lemon-o:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-bookmark-o:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-facebook-f:before, .fa-facebook:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-feed:before, .fa-rss:before {
  content: "";
}

.fa-hdd-o:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-hand-o-right:before {
  content: "";
}

.fa-hand-o-left:before {
  content: "";
}

.fa-hand-o-up:before {
  content: "";
}

.fa-hand-o-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-group:before, .fa-users:before {
  content: "";
}

.fa-chain:before, .fa-link:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-cut:before, .fa-scissors:before {
  content: "";
}

.fa-copy:before, .fa-files-o:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-save:before, .fa-floppy-o:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-money:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-unsorted:before, .fa-sort:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-rotate-left:before, .fa-undo:before {
  content: "";
}

.fa-legal:before, .fa-gavel:before {
  content: "";
}

.fa-dashboard:before, .fa-tachometer:before {
  content: "";
}

.fa-comment-o:before {
  content: "";
}

.fa-comments-o:before {
  content: "";
}

.fa-flash:before, .fa-bolt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-paste:before, .fa-clipboard:before {
  content: "";
}

.fa-lightbulb-o:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-bell-o:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-file-text-o:before {
  content: "";
}

.fa-building-o:before {
  content: "";
}

.fa-hospital-o:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-mobile-phone:before, .fa-mobile:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-folder-o:before {
  content: "";
}

.fa-folder-open-o:before {
  content: "";
}

.fa-smile-o:before {
  content: "";
}

.fa-frown-o:before {
  content: "";
}

.fa-meh-o:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-keyboard-o:before {
  content: "";
}

.fa-flag-o:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "";
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-unlink:before, .fa-chain-broken:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-calendar-o:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-minus-square-o:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-toggle-down:before, .fa-caret-square-o-down:before {
  content: "";
}

.fa-toggle-up:before, .fa-caret-square-o-up:before {
  content: "";
}

.fa-toggle-right:before, .fa-caret-square-o-right:before {
  content: "";
}

.fa-euro:before, .fa-eur:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-dollar:before, .fa-usd:before {
  content: "";
}

.fa-rupee:before, .fa-inr:before {
  content: "";
}

.fa-cny:before, .fa-rmb:before, .fa-yen:before, .fa-jpy:before {
  content: "";
}

.fa-ruble:before, .fa-rouble:before, .fa-rub:before {
  content: "";
}

.fa-won:before, .fa-krw:before {
  content: "";
}

.fa-bitcoin:before, .fa-btc:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-youtube-play:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitbucket-square:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-gittip:before, .fa-gratipay:before {
  content: "";
}

.fa-sun-o:before {
  content: "";
}

.fa-moon-o:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-arrow-circle-o-right:before {
  content: "";
}

.fa-arrow-circle-o-left:before {
  content: "";
}

.fa-toggle-left:before, .fa-caret-square-o-left:before {
  content: "";
}

.fa-dot-circle-o:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-turkish-lira:before, .fa-try:before {
  content: "";
}

.fa-plus-square-o:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-institution:before, .fa-bank:before, .fa-university:before {
  content: "";
}

.fa-mortar-board:before, .fa-graduation-cap:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-automobile:before, .fa-car:before {
  content: "";
}

.fa-cab:before, .fa-taxi:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-file-pdf-o:before {
  content: "";
}

.fa-file-word-o:before {
  content: "";
}

.fa-file-excel-o:before {
  content: "";
}

.fa-file-powerpoint-o:before {
  content: "";
}

.fa-file-photo-o:before, .fa-file-picture-o:before, .fa-file-image-o:before {
  content: "";
}

.fa-file-zip-o:before, .fa-file-archive-o:before {
  content: "";
}

.fa-file-sound-o:before, .fa-file-audio-o:before {
  content: "";
}

.fa-file-movie-o:before, .fa-file-video-o:before {
  content: "";
}

.fa-file-code-o:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-life-bouy:before, .fa-life-buoy:before, .fa-life-saver:before, .fa-support:before, .fa-life-ring:before {
  content: "";
}

.fa-circle-o-notch:before {
  content: "";
}

.fa-ra:before, .fa-resistance:before, .fa-rebel:before {
  content: "";
}

.fa-ge:before, .fa-empire:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-y-combinator-square:before, .fa-yc-square:before, .fa-hacker-news:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-wechat:before, .fa-weixin:before {
  content: "";
}

.fa-send:before, .fa-paper-plane:before {
  content: "";
}

.fa-send-o:before, .fa-paper-plane-o:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-circle-thin:before {
  content: "";
}

.fa-header:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-sliders:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-soccer-ball-o:before, .fa-futbol-o:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-newspaper-o:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bell-slash-o:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-cc:before {
  content: "";
}

.fa-shekel:before, .fa-sheqel:before, .fa-ils:before {
  content: "";
}

.fa-meanpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-intersex:before, .fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-facebook-official:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-hotel:before, .fa-bed:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-yc:before, .fa-y-combinator:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-battery-4:before, .fa-battery:before, .fa-battery-full:before {
  content: "";
}

.fa-battery-3:before, .fa-battery-three-quarters:before {
  content: "";
}

.fa-battery-2:before, .fa-battery-half:before {
  content: "";
}

.fa-battery-1:before, .fa-battery-quarter:before {
  content: "";
}

.fa-battery-0:before, .fa-battery-empty:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-sticky-note-o:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-hourglass-o:before {
  content: "";
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
  content: "";
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hand-grab-o:before, .fa-hand-rock-o:before {
  content: "";
}

.fa-hand-stop-o:before, .fa-hand-paper-o:before {
  content: "";
}

.fa-hand-scissors-o:before {
  content: "";
}

.fa-hand-lizard-o:before {
  content: "";
}

.fa-hand-spock-o:before {
  content: "";
}

.fa-hand-pointer-o:before {
  content: "";
}

.fa-hand-peace-o:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-tv:before, .fa-television:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-calendar-plus-o:before {
  content: "";
}

.fa-calendar-minus-o:before {
  content: "";
}

.fa-calendar-times-o:before {
  content: "";
}

.fa-calendar-check-o:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-map-o:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-commenting-o:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-credit-card-alt:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-pause-circle-o:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stop-circle-o:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-wheelchair-alt:before {
  content: "";
}

.fa-question-circle-o:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asl-interpreting:before, .fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-deafness:before, .fa-hard-of-hearing:before, .fa-deaf:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-signing:before, .fa-sign-language:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-google-plus-circle:before, .fa-google-plus-official:before {
  content: "";
}

.fa-fa:before, .fa-font-awesome:before {
  content: "";
}

.fa-handshake-o:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-o:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-book-o:before {
  content: "";
}

.fa-vcard:before, .fa-address-card:before {
  content: "";
}

.fa-vcard-o:before, .fa-address-card-o:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-circle-o:before {
  content: "";
}

.fa-user-o:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-drivers-license:before, .fa-id-card:before {
  content: "";
}

.fa-drivers-license-o:before, .fa-id-card-o:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-thermometer-4:before, .fa-thermometer:before, .fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-bathtub:before, .fa-s15:before, .fa-bath:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-times-rectangle-o:before, .fa-window-close-o:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-eercast:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-snowflake-o:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/webfonts/slick/slick.eot");
  src: url("../assets/webfonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../assets/webfonts/slick/slick.woff") format("woff"), url("../assets/webfonts/slick/slick.ttf") format("truetype"), url("../assets/webfonts/slick/slick.svg#slick") format("svg");
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  opacity: .75;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  opacity: .25;
  color: #000;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

@font-face {
  font-family: Marshalls-Sans-Light;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Sans-Light-Italic;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Sans-Regular;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Sans-Regular-Italic;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Sans-Medium;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Sans-Medium-Italic;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Sans-SemiBold;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Sans-SemiBold-Italic;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Sans-Bold;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serifs/Marshalls-Sans-Bold.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Sans-Bold-Italic;
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Light;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Light-Italic;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Regular;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Regular-Italic;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Medium;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Medium-Italic;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Demi;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Demi-Italic;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Bold;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serifs/Marshalls-Serif-Bold.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold.woff2") format("woff2");
}

@font-face {
  font-family: Marshalls-Serif-Bold-Italic;
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.ttf");
  src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.woff2") format("woff2");
}

@-webkit-keyframes fadeInFromNone {
  0% {
    opacity: 0;
    display: none;
  }

  1% {
    opacity: 0;
    display: block;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
    display: none;
  }

  1% {
    opacity: 0;
    display: block;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

.your-selector {
  opacity: 0;
  transition: all 1s cubic-bezier(.215, .61, .355, 1);
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}

.animated-block.your-selector, .animated-block .your-selector {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes pulseAnimation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulseAnimation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes bounce {
  from, 10%, 26.5%, 40%, 50%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%, 21.5% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  35% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  45% {
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
  }
}

@keyframes bounce {
  from, 10%, 26.5%, 40%, 50%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%, 21.5% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  35% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  45% {
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
  }
}

@-webkit-keyframes bounceLeft {
  from, 10%, 26.5%, 40%, 50%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%, 21.5% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  35% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  45% {
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0);
  }
}

@keyframes bounceLeft {
  from, 10%, 26.5%, 40%, 50%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%, 21.5% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  35% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  45% {
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0);
  }
}

.bounceLeft {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation-name: bounceLeft;
  animation-name: bounceLeft;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  color: #0024cc;
  background-color: #fff;
  margin: 0;
  font-family: Marshalls-Sans-Light, Marshalls-Serif-Light, helvetica, sans-serif;
  font-size: 1.8rem;
  line-height: 26px;
}

@media only screen and (width <= 1000px) {
  body {
    font-size: 1.4rem;
    line-height: 20px;
    overflow-x: hidden;
  }
}

h1, h2, h3, h4, h5, h6 {
  clear: both;
  margin: 0;
  font-family: Marshalls-Sans-Regular, Marshalls-Serif-Regular, Marshalls-Serif-Medium, Marshalls-Sans-SemiBold, Marshalls-Serif-Demi, Marshalls-Sans-Bold, Marshalls-Serif-Bold, sans-serif;
  font-weight: normal;
}

h1 {
  letter-spacing: -4.3px;
  font-size: 8.6rem;
  line-height: 86px;
}

@media only screen and (width <= 1000px) {
  h1 {
    letter-spacing: -2px;
    font-size: 4rem;
    line-height: 40px;
  }
}

h1.sub-hero {
  letter-spacing: -6px;
  font-family: Marshalls-Sans-Light;
  font-size: 12rem;
  line-height: 96px;
}

@media only screen and (width <= 1400px) {
  h1.sub-hero {
    font-size: 8.6rem;
    line-height: 68px;
  }
}

@media only screen and (width <= 1000px) {
  h1.sub-hero {
    letter-spacing: -2.2px;
    font-size: 4.4rem;
    line-height: 44px;
  }
}

h2 {
  letter-spacing: -2.6px;
  font-size: 5.2rem;
  line-height: normal;
}

@media only screen and (width <= 1000px) {
  h2 {
    letter-spacing: -1.7px;
    font-size: 3.4rem;
    line-height: normal;
  }
}

h3 {
  letter-spacing: -2px;
  font-size: 4rem;
  line-height: normal;
}

@media only screen and (width <= 1000px) {
  h3 {
    letter-spacing: -1.7px;
    font-size: 3.4rem;
    line-height: normal;
  }
}

h4 {
  font-size: 2.4rem;
  line-height: 29px;
}

@media only screen and (width <= 1000px) {
  h4 {
    font-size: 2rem;
    line-height: 23px;
  }
}

h5 {
  letter-spacing: -.36px;
  font-size: 1.8rem;
  line-height: 19.8px;
}

@media only screen and (width <= 1000px) {
  h5 {
    letter-spacing: -.28px;
    font-size: 1.4rem;
    line-height: 14px;
  }
}

h6 {
  letter-spacing: 1.2px;
  font-family: Marshalls-Sans-Medium;
  font-size: 1.2rem;
  line-height: 12px;
}

.upper {
  text-transform: uppercase;
}

p {
  letter-spacing: -.36px;
  margin-bottom: 1.5em;
  font-size: 1.8rem;
  line-height: 19.8px;
}

@media only screen and (width <= 1000px) {
  p {
    letter-spacing: -.3px;
    font-size: 1.5rem;
    line-height: 16.5px;
  }
}

p.sub-hero {
  letter-spacing: -1.4px;
  font-size: 2.8rem;
  line-height: 28px;
}

@media only screen and (width <= 1400px) {
  p.sub-hero {
    font-size: 2.2rem;
    line-height: 22px;
  }
}

@media only screen and (width <= 1000px) {
  p.sub-hero {
    letter-spacing: -1px;
    max-width: 226px;
    font-size: 2rem;
    line-height: 20px;
  }
}

p.desc {
  letter-spacing: -1.6px;
  font-size: 3.2rem;
  line-height: normal;
}

@media only screen and (width <= 1000px) {
  p.desc {
    letter-spacing: -1.2px;
    font-size: 2.4rem;
    line-height: normal;
  }
}

strong {
  font-family: Marshalls-Serif-Bold-Italic;
}

b {
  font-family: Marshalls-Sans-Regular, Marshalls-Serif-Regular, Marshalls-Serif-Medium, Marshalls-Sans-SemiBold, Marshalls-Serif-Demi, Marshalls-Sans-Bold, Marshalls-Serif-Bold, sans-serif;
}

dfn, cite, em, i {
  font-style: italic;
}

sup, sub {
  height: 0;
  padding-left: 3px;
  font-size: 1.2rem;
  line-height: 0;
  position: relative;
}

sup {
  top: -1rem;
}

a {
  color: #0024cc;
  text-decoration: none;
}

a:visited {
  color: #0024cc;
}

a:hover, a:focus, a:active {
  color: #0024cc;
  outline: 0;
  text-decoration: underline;
}

p a {
  text-decoration: underline;
}

a {
  cursor: pointer;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul li, ol li {
  margin-left: 15px;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul, li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-family: Marshalls-Sans-Regular, Marshalls-Serif-Regular, Marshalls-Serif-Medium, Marshalls-Sans-SemiBold, Marshalls-Serif-Demi, Marshalls-Sans-Bold, Marshalls-Serif-Bold, sans-serif;
}

dd {
  margin: 0 1.5em 1.5em;
}

hr {
  background-color: #cbcbcb;
  border: 0;
  width: 100%;
  height: 1px;
  margin: 1.5em 0;
}

img {
  border: 0;
  height: auto;
}

.ctn-img img {
  width: 100%;
}

@media only screen and (width <= 1000px) {
  img {
    max-width: 100%;
  }
}

figure {
  margin: 0;
}

table {
  border-spacing: 0;
  border-collapse: separate;
  padding: 0;
}

.ctn-main {
  width: 1512px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.ctn-main.-small {
  width: 940px;
}

.ctn-main.-large {
  width: 1130px;
}

.ctn-main.-slider-ctn {
  width: 1015px;
}

.video-container {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.video-container iframe {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width >= 426px) {
  .video-container iframe {
    height: 100%;
  }
}

.-block {
  clear: both;
  display: block;
}

.-text-centered {
  text-align: center;
}

.-v-align {
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.-alignleft {
  float: left;
  margin-right: 1.5em;
  display: inline;
}

.-alignright {
  float: right;
  margin-left: 1.5em;
  display: inline;
}

.-valign {
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.-centered {
  margin: 0 auto;
  display: table;
}

.-capitalize :first-letter {
  text-transform: uppercase;
}

.-hide, .-hidden {
  display: none !important;
}

.-desktop-only {
  display: block;
}

@media only screen and (width <= 1050px) {
  .-desktop-only {
    display: none;
  }
}

.-mobile-only {
  display: none !important;
}

@media only screen and (width <= 1050px) {
  .-mobile-only {
    display: block !important;
  }
}

.-colored {
  color: #0024cc;
}

.-footnote {
  font-size: 1.4rem;
  font-style: italic;
}

.button, .button:visited {
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  -webkit-appearance: none;
  appearance: none;
  white-space: nowrap;
  background-color: #0024cc;
  border: 1px solid #0000;
  border-radius: 100px;
  padding: 16px 24px;
  font-family: Marshalls-Sans-Regular, Marshalls-Serif-Regular, Marshalls-Serif-Medium, Marshalls-Sans-SemiBold, Marshalls-Serif-Demi, Marshalls-Sans-Bold, Marshalls-Serif-Bold, sans-serif;
  font-size: 1.8rem;
  line-height: 18px;
  text-decoration: none;
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

@media only screen and (width <= 1000px) {
  .button, .button:visited {
    letter-spacing: -.32px;
    padding: 12px 20px;
    font-size: 1.6rem;
    line-height: 16px;
  }
}

.button:active, .button:focus, .button:hover {
  color: #fff;
  text-decoration: none;
}

@media only screen and (width >= 1051px) {
  .button:active, .button:focus, .button:hover {
    background-color: #142a74;
    border: 1px solid #142a74;
  }
}

.cta-group {
  width: 100%;
}

.cta-group .cta-arrow {
  letter-spacing: -.36px;
  text-transform: uppercase;
  border-bottom: 1px solid #0024cc;
  margin-bottom: 24px;
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  display: block;
  position: relative;
}

.cta-group .cta-arrow:nth-child(2) {
  padding-bottom: 0;
}

.cta-group .cta-arrow:after {
  content: "";
  background: url("assets/img/icon-arrow.svg") center no-repeat;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  right: 0;
}

.cta-group .cta-arrow:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

@media only screen and (width <= 1050px) {
  .cta-group .cta-arrow {
    letter-spacing: -.32px;
    font-size: 16px;
    line-height: normal;
  }
}

.--leaf {
  border-radius: 0 12.5%;
}

@media only screen and (width >= 1051px) {
  .grid-item:hover .button {
    color: #fff;
    background-color: #142a74;
    border: 1px solid #142a74;
    text-decoration: none;
  }
}

.no-margin {
  margin-bottom: 0;
}

.--invisible {
  opacity: .35;
  pointer-events: none;
}

.infinite-scroll .paging-navigation, .infinite-scroll.neverending .site-footer {
  display: none;
}

.infinity-end.neverending .site-footer {
  display: block;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
  z-index: 100000;
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  width: auto;
  height: auto;
  padding: 15px 23px 14px;
  font-family: Marshalls-Sans-Regular, Marshalls-Serif-Regular, Marshalls-Serif-Medium, Marshalls-Sans-SemiBold, Marshalls-Serif-Demi, Marshalls-Sans-Bold, Marshalls-Serif-Bold, sans-serif;
  font-size: 1.4rem;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: 5px;
  left: 5px;
  box-shadow: 0 0 2px 2px #c80000e6;
  clip: auto !important;
}

.site-body, .site-content {
  position: relative;
}

.col-ctn {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  display: -webkit-flex;
  display: flex;
}

.col-ctn + .button {
  margin-top: 20px;
}

@media only screen and (width <= 1000px) {
  .col-ctn + .button {
    margin-top: 0;
  }
}

.col-1-3 {
  width: calc(33.3333% - 10px);
}

.col-2-3 {
  width: calc(66.6667% - 10px);
}

.col-1-2 {
  width: calc(50% - 10px);
}

.col-1-4 {
  width: calc(25% - 10px);
}

.col-3-4 {
  width: calc(75% - 10px);
}

.col-1-5 {
  width: calc(20% - 10px);
}

.col-2-5 {
  width: calc(40% - 15px);
}

.col-3-5 {
  width: calc(60% - 15px);
}

.col-slider {
  padding: 0 10px;
  display: inline-block;
}

@media only screen and (width <= 767px) {
  .col-slider {
    padding: 0;
  }

  .ctn-main > .col-ctn > .col-1-3, .ctn-main > .col-ctn > .col-2-3, .ctn-main > .col-ctn > .col-1-2, .ctn-main > .col-ctn > .col-1-4, .ctn-main > .col-ctn > .col-3-4, .ctn-main > .col-ctn > .col-1-5, .ctn-main > .col-ctn > .col-2-5, .ctn-main > .col-ctn > .col-3-5 {
    width: 100%;
  }

  .col-ctn .col-ctn {
    justify-content: space-between;
  }
}

.mobile-only {
  display: none;
}

@media only screen and (width <= 1000px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .mobile-first {
    -webkit-order: 1 !important;
    order: 1 !important;
  }
}

.navigation ul {
  margin: 0;
  list-style: none;
}

.navigation li {
  margin: 0;
}

section.main {
  margin-bottom: 0;
}

section a:not(.button) {
  text-decoration: none;
}

.subtitle {
  margin-bottom: 5px;
  font-family: Marshalls-Sans-Regular, Marshalls-Serif-Regular, Marshalls-Serif-Medium, Marshalls-Sans-SemiBold, Marshalls-Serif-Demi, Marshalls-Sans-Bold, Marshalls-Serif-Bold, sans-serif;
  font-size: 2rem;
  line-height: 24px;
  display: block;
}

@media only screen and (width <= 1000px) {
  .subtitle {
    font-size: 1.8rem;
    line-height: 21px;
  }
}

.subtitle + div {
  margin-top: 35px;
}

@media only screen and (width <= 1000px) {
  .subtitle + div {
    margin-top: 15px;
  }
}

.description {
  margin-top: 5px;
}

.ctn-main > .description {
  padding-right: 70px;
}

@media only screen and (width <= 1000px) {
  .ctn-main > .description {
    padding-right: 0;
  }
}

.bits-FormControl {
  box-sizing: border-box;
  vertical-align: middle;
  font: inherit;
  background: none;
  border: 1px solid;
  width: 100%;
  margin: 0;
  padding: .4em .5em;
  font-size: 100%;
  position: relative;
}

.bits-FormControl[type="search"] {
  box-sizing: border-box;
}

.bits-FormControl:disabled, .bits-FormControl.is-disabled {
  cursor: default;
}

.bits-FormControl--checkbox, .bits-FormControl--radio {
  margin-top: -1px;
  margin-top: 0\9 ;
  border: 0;
  width: auto;
  padding: 0;
}

.bits-FormControl--radio {
  margin-top: -2px;
  margin-top: 0\9 ;
}

.content-page {
  margin-bottom: 80px;
}

.content-page .ctn-main > * {
  margin-left: 250px;
}

@media only screen and (width <= 768px) {
  .content-page .ctn-main > * {
    margin-left: 32px;
  }
}

.content-page .ctn-main > :-webkit-any(h2) {
  margin-left: 0;
}

.content-page .ctn-main > :is(h2) {
  margin-left: 0;
}

.content-page h2 {
  letter-spacing: -4.3px;
  margin: 45px 0 0;
  font-family: Marshalls-Serif-Demi-Italic;
  font-size: 86px;
  font-style: italic;
  font-weight: 700;
  line-height: 100%;
}

@media only screen and (width <= 768px) {
  .content-page h2 {
    letter-spacing: -2.2px;
    margin: 40px 0;
    font-size: 44px;
    line-height: normal;
  }
}

.content-page h3 {
  letter-spacing: -2.6px;
  margin-top: 80px;
  margin-bottom: 40px;
  font-family: Marshalls-Serif-Demi-Italic;
  font-size: 52px;
  font-style: italic;
  font-weight: 700;
}

@media only screen and (width <= 768px) {
  .content-page h3 {
    letter-spacing: -1.8px;
    font-size: 36px;
    line-height: normal;
  }
}

.content-page .accordion-wrapper {
  margin-left: 250px;
}

@media only screen and (width <= 768px) {
  .content-page .accordion-wrapper {
    margin-left: 18px;
  }
}

.content-page .accordion-wrapper ul {
  margin: 0;
  list-style: none;
}

.content-page .container-btn {
  margin-bottom: 40px;
}

.content-page .accordion-title {
  letter-spacing: -1.4px;
  cursor: pointer;
  padding-bottom: 18px;
  padding-left: 57px;
  font-size: 28px;
  line-height: normal;
  position: relative;
}

@media only screen and (width <= 768px) {
  .content-page .accordion-title {
    letter-spacing: -1px;
    font-size: 20px;
    line-height: normal;
  }
}

.content-page .accordion-title:before {
  content: "";
  cursor: pointer;
  border: 2px solid var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024cc);
  background: var(--marshalls-brand-color-palette-rgb-marshalls-soft-purple-rgb, #e1cfe6);
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: block;
  position: absolute;
  top: -6.5px;
  left: 0;
}

@media only screen and (width <= 768px) {
  .content-page .accordion-title:before {
    -webkit-transform: scale(.75);
    transform: scale(.75);
  }
}

.content-page .accordion-title:after {
  content: "+";
  text-align: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-size: 32px;
  line-height: 27px;
  transition: all .25s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 8px;
  overflow: hidden;
}

@media only screen and (width <= 768px) {
  .content-page .accordion-title:after {
    top: 0;
    -webkit-transform: scale(.75);
    transform: scale(.75);
  }
}

.content-page .expanded .accordion-title:after {
  content: "-";
}

.content-page .content {
  color: var(--marshalls-brand-color-palette-rgb-marshalls-navy-rgb, #142a74);
  letter-spacing: -.36px;
  padding-left: 57px;
  font-size: 18px;
  line-height: 110%;
  display: none;
}

.content-page .content a {
  text-decoration: underline;
}

.content-page .content ul li {
  font-size: 18px;
  list-style: disc;
}

@media only screen and (width <= 1000px) {
  .content-page .content ul li {
    font-size: 15px;
  }
}

.content-page .content ol li {
  list-style: number;
}

.content-page .content p:last-child {
  margin-bottom: 0;
}

.content-page .content ol:p:last-child {
  margin-bottom: 0;
}

.content-page .content ul:p:last-child {
  margin-bottom: 0;
}

body.grants {
  background-color: #f4f1ee;
}

body.grants .site-header .site-header__logo {
  width: auto;
}

body.grants .site-header .site-header__logo img {
  object-fit: contain;
  object-position: left;
  width: 300px;
  height: 75px;
  display: block;
}

body.grants .site-header .button {
  background-color: #142a74;
}

body.grants .site-header .site-header__right .menu__item:before {
  -webkit-transform: rotate(15deg) !important;
  transform: rotate(15deg) !important;
}

@media only screen and (width <= 1200px) {
  body.grants .site-header .site-header__right .menu__item:before {
    top: 10px;
    left: -300px;
    -webkit-transform: translateX(-250px) !important;
    transform: translateX(-250px) !important;
  }

  body.grants .site-header__right p {
    display: none;
  }

  body.grants .site-header__right p:has( + .open) {
    z-index: 101;
    text-align: center;
    width: 100%;
    display: block;
    position: absolute;
    top: 120px;
    left: 0;
  }

  body.grants .site-header .site-header__menu-ul-buttons.open {
    text-align: center;
    padding-top: 60px;
    top: 100px;
  }

  body.grants .site-header .site-header__menu-ul-buttons.open li:first-child {
    margin-left: 0;
  }
}

body.grants .hero-section .top-text p {
  color: var(--Marshalls-Brand-Color-Palette-Marshalls-Off-White---New, #f4f1ee);
  letter-spacing: -1.4px;
  font-size: 28px;
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
}

@media only screen and (width <= 1400px) {
  body.grants .hero-section .top-text p {
    letter-spacing: -1px;
    font-size: 20px;
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
}

@media only screen and (width <= 1050px) {
  body.grants .hero-section .top-text p {
    letter-spacing: -.32px;
    font-size: 16px;
    line-height: 110%;
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

body.grants .hero-section h1.sub-hero {
  color: var(--marshalls-brand-color-palette-rgb-marshalls-soft-purple-rgb, #e1cfe6);
  letter-spacing: -5.5px;
  font-family: Marshalls-Serif-Bold-Italic;
  font-size: 110px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (width <= 1400px) {
  body.grants .hero-section h1.sub-hero {
    letter-spacing: -4.2px;
    white-space: nowrap;
    font-size: 76px;
  }
}

@media only screen and (width <= 1050px) {
  body.grants .hero-section h1.sub-hero {
    letter-spacing: -2.2px;
    font-size: 38px;
  }
}

body.grants .hero-section h1.sub-hero strong {
  color: var(--marshalls-brand-color-palette-rgb-marshalls-soft-purple-rgb, #e1cfe6);
  letter-spacing: -2.6px;
  font-family: Marshalls-Sans-Light;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  display: block;
}

@media only screen and (width <= 1400px) {
  body.grants .hero-section h1.sub-hero strong {
    letter-spacing: -2.2px;
    font-size: 40px;
  }
}

@media only screen and (width <= 1050px) {
  body.grants .hero-section h1.sub-hero strong {
    letter-spacing: -1.4px;
    font-size: 28px;
  }
}

body.grants .desc-section p.desc {
  line-height: 34.5px;
}

body.grants .desc-section p.desc a {
  text-decoration: underline;
}

body.grants .desc-section a {
  text-decoration-skip-ink: none;
  text-decoration-thickness: 2px !important;
}

@media only screen and (width <= 1400px) {
  body.grants .hero-section .ctn-main .hero-right p.sub-hero {
    letter-spacing: -.32px;
    font-size: 22px;
    line-height: 110%;
  }
}

@media only screen and (width <= 1050px) {
  body.grants .hero-section .ctn-main .hero-right p.sub-hero {
    letter-spacing: -.32px;
    margin: 24px 24px 40px;
    font-size: 16px;
    line-height: 110%;
  }
}

body.grants .ctn-main .desc-wrapper p {
  max-width: 764px;
}

body.grants .site-content p.disclaim {
  font-size: 14px;
  line-height: 18px;
}

.grants-instructions {
  height: auto;
  min-height: auto;
  margin-bottom: 40px;
}

@media only screen and (width <= 1050px) {
  .grants-instructions {
    height: auto;
  }
}

.grants-instructions a {
  text-decoration-skip-ink: none;
  text-decoration-thickness: 1px !important;
}

@media only screen and (width <= 1050px) {
  .grants-instructions .toggle {
    display: none;
  }
}

.grants-instructions.--fixed-container {
  display: none;
}

.grants-instructions.--sticky > div {
  width: 100%;
  position: fixed;
  top: 129px;
  left: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

@media only screen and (width <= 1050px) {
  .grants-instructions.--sticky > div {
    -webkit-transform: translateY(calc(54px - 100%));
    transform: translateY(calc(54px - 100%));
  }
}

.grants-instructions.--sticky > div .ctn-main {
  padding-left: 0;
  padding-right: 0;
}

.grants-instructions.--sticky > div .chapter-nav {
  border-radius: 0 0 12px 12px;
  -webkit-transform: translateY(54px);
  transform: translateY(54px);
  box-shadow: 0 0 20px #00000040;
}

.grants-instructions.--sticky > div .chapter-contents {
  opacity: 0;
  border-radius: 0;
}

@media only screen and (width <= 1050px) {
  .grants-instructions.--sticky > div .chapter-contents {
    opacity: 1;
  }
}

@media only screen and (width <= 1200px) {
  .grants-instructions.--sticky > div {
    top: 100px;
  }
}

.grants-instructions.--sticky > div.--once, .grants-instructions.--sticky > div.--open {
  transition: -webkit-transform .4s ease-out, transform .4s ease-out;
}

.grants-instructions.--sticky > div.--open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.grants-instructions.--sticky > div.--open .--on {
  display: block;
}

.grants-instructions.--sticky > div.--open .--off {
  display: none;
}

.grants-instructions.--sticky > div.--open .toggle:after {
  content: "-";
}

.grants-instructions.--sticky > div.--open .chapter-nav {
  border-radius: 0 0 0 12px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@media only screen and (width <= 1050px) {
  .grants-instructions.--sticky > div.--open .chapter-nav {
    border-radius: 0;
  }

  .grants-instructions.--sticky > div.--open .chapter-nav .toggle {
    background: #e6dfda;
    width: calc(100% + 24px);
    padding-top: 17px;
    position: fixed;
    top: 0;
    -webkit-transform: translateX(-24px);
    transform: translateX(-24px);
  }

  .grants-instructions.--sticky > div.--open .chapter-nav .toggle:before {
    top: 4px;
  }

  .grants-instructions.--sticky > div.--open .chapter-nav .toggle:after {
    top: 12px;
  }

  .grants-instructions.--sticky > div.--open .chapter-nav .toggle.mobile {
    display: block;
  }
}

.grants-instructions.--sticky > div.--open .chapter-contents {
  opacity: 1;
  background-color: #fff;
  border-radius: 0 0 12px;
}

@media only screen and (width <= 1050px) {
  .grants-instructions.--sticky > div.--open .chapter-contents {
    border-radius: 0 0 12px 12px;
  }
}

.grants-instructions.--sticky > div.--open .chapter-contents .toggle {
  display: none;
}

.grants-instructions.--sticky > div.--open .ctn-main {
  border-radius: 0 0 12px 12px;
  max-height: calc(100dvh - 100px);
  overflow: hidden;
  box-shadow: 0 0 20px #00000040;
}

@media only screen and (width <= 1050px) {
  .grants-instructions.--sticky > div.--open .ctn-main {
    overflow: scroll;
  }
}

.grants-instructions.--sticky .toggle {
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.grants-instructions.--sticky .chapter-contents {
  background-color: #fff;
}

@media only screen and (width <= 1050px) {
  .grants-instructions.--sticky .chapter-contents {
    border-radius: 0 0 12px 12px;
    padding-bottom: 60px;
  }

  .grants-instructions.--sticky .ctn-main {
    padding-left: 0;
  }
}

.grants-instructions.--sticky .chapter-nav .toggle {
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .grants-instructions.--sticky .chapter-nav .toggle {
    display: none;
  }
}

.grants-instructions.--sticky .chapter-contents .toggle {
  display: none;
}

@media only screen and (width <= 1050px) {
  .grants-instructions.--sticky .chapter-contents .toggle {
    background-color: #f4f1ee;
    display: -webkit-flex;
    display: flex;
  }
}

.grants-instructions .toggle {
  cursor: pointer;
  white-space: nowrap;
  height: 55px;
  padding-left: 40px;
  line-height: 55px;
  display: none;
  position: absolute;
  bottom: 0;
}

@media only screen and (width <= 1050px) {
  .grants-instructions .toggle {
    width: 100%;
    padding-left: 65px;
    left: 0;
  }
}

.grants-instructions .toggle:before {
  content: "";
  cursor: pointer;
  border: 2px solid var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024cc);
  background: var(--marshalls-brand-color-palette-rgb-marshalls-soft-purple-rgb, #e1cfe6);
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: block;
  position: absolute;
  top: 6px;
  left: -70px;
}

@media only screen and (width <= 768px) {
  .grants-instructions .toggle:before {
    -webkit-transform: scale(.75);
    transform: scale(.75);
  }
}

@media only screen and (width <= 1050px) {
  .grants-instructions .toggle:before {
    left: calc(50% - 155px);
  }
}

.grants-instructions .toggle:after {
  content: "+";
  text-align: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-size: 32px;
  line-height: 24px;
  transition: all .25s ease-out;
  display: block;
  position: absolute;
  top: 14px;
  left: -61px;
  overflow: hidden;
}

@media only screen and (width <= 768px) {
  .grants-instructions .toggle:after {
    top: -2px;
    -webkit-transform: scale(.75);
    transform: scale(.75);
  }
}

@media only screen and (width <= 1050px) {
  .grants-instructions .toggle:after {
    top: 14px;
    left: calc(50% - 146px);
  }
}

.grants-instructions .toggle span {
  color: var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024cc);
  text-align: center;
  letter-spacing: -.36px;
  text-transform: uppercase;
  font-family: Marshalls-Sans-SemiBold;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (width <= 1050px) {
  .grants-instructions .toggle span {
    width: 100%;
    padding-left: 6px;
  }
}

.grants-instructions .toggle .--on {
  display: none;
}

.grants-instructions .ctn-main {
  -webkit-flex-flow: row;
  flex-flow: row;
  align-content: stretch;
  align-items: stretch;
  height: 450px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .grants-instructions .ctn-main {
    height: auto;
    display: block;
  }
}

.grants-instructions .chapter-nav {
  background-color: #e6dfda;
  border-radius: 12px 0 0 12px;
  min-width: 464px;
  padding: 54px 108px 54px 92px;
  position: relative;
}

@media only screen and (width <= 1050px) {
  .grants-instructions .chapter-nav {
    border-radius: 12px 12px 0 0;
    min-width: auto;
    padding: 60px 20px 20px 40px;
  }
}

.grants-instructions .chapter-nav ul {
  list-style: none;
}

.grants-instructions .chapter-nav .toggle {
  display: none;
}

.grants-instructions .chapter-nav .chapter-nav__item {
  white-space: nowrap;
  margin: 0;
}

.grants-instructions .chapter-nav .chapter-nav__item a {
  color: var(--marshalls-brand-color-palette-rgb-marshalls-navy-rgb, #142a74);
  letter-spacing: -1.4px;
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 28px;
  line-height: normal;
  display: block;
}

.grants-instructions .chapter-nav .chapter-nav__item.active a {
  color: #0024cc;
  font-family: Marshalls-Sans-SemiBold;
  font-weight: 600;
  position: relative;
}

.grants-instructions .chapter-nav .chapter-nav__item.active a:before {
  content: "";
  background: url("assets/img/icon-softblue-arrow.svg") center / 100% 100% no-repeat;
  width: 42px;
  height: 11.5px;
  position: absolute;
  top: 10px;
  left: -54px;
}

@media only screen and (width <= 1050px) {
  .grants-instructions .chapter-nav .chapter-nav__item.active a:before {
    width: 20px;
    left: -30px;
  }
}

.grants-instructions .chapter-nav .chapter-nav__item:not(.active) a:hover {
  text-decoration: underline;
}

.grants-instructions .chapter-contents {
  background-color: #fff;
  border-radius: 0 12px 12px 0;
  padding: 50px 30px 50px 126px;
  position: relative;
}

@media only screen and (width <= 1050px) {
  .grants-instructions .chapter-contents {
    border-radius: 0 0 12px 12px;
    padding: 20px 20px 20px 30px;
  }
}

.grants-instructions .chapter-contents > div {
  display: none;
}

.grants-instructions .chapter-contents > div.active {
  display: block;
}

.grants-instructions .chapter-contents > div p {
  color: var(--Black, #000);
  margin-top: 0;
}

.grants-instructions .chapter-contents > div p a:not(.button):hover {
  color: #0024cc;
}

.grants-instructions .chapter-contents > div a {
  color: #000;
  font-family: Marshalls-Sans-Regular;
  text-decoration: underline;
}

.grants-instructions .chapter-contents > div a.button.smooth {
  color: #fff;
  text-decoration: none;
}

.grants-instructions .chapter-contents > div ul {
  margin-bottom: 1.5em;
}

.grants-instructions .chapter-contents > div li {
  color: var(--Black, #000);
  font-size: 1.8rem;
  line-height: normal;
}

@media only screen and (width <= 1000px) {
  .grants-instructions .chapter-contents > div li {
    font-size: 1.5rem;
    line-height: normal;
  }
}

.grants-instructions .chapter-contents > div h3 {
  color: var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024cc);
  letter-spacing: -2px;
  margin-bottom: 40px;
  font-family: Marshalls-Serif-Bold-Italic;
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.grants-instructions .chapter-contents > div span {
  color: #142a74;
  letter-spacing: -.36px;
  text-transform: uppercase;
  font-family: Marshalls-Sans-SemiBold;
  font-size: 18px;
  font-weight: 600;
}

.grants-instructions .chapter-contents > div .chapter-link {
  color: #0024cc;
  letter-spacing: -.36px;
  text-transform: uppercase;
  font-family: Marshalls-Sans-SemiBold;
  font-size: 18px;
  font-weight: 600;
}

.grants-instructions .chapter-contents > div .chapter-link:hover {
  color: #142a74;
}

@media only screen and (width <= 1050px) {
  .grants-instructions .chapter-contents > div .chapter-link {
    position: static;
  }
}

.grants-instructions .chapter-contents .--scrollable {
  height: 236px;
  margin-bottom: 34px;
  padding-right: 170px;
  overflow: hidden auto;
}

@media only screen and (width <= 1050px) {
  .grants-instructions .chapter-contents .--scrollable {
    height: auto;
    padding-right: 0;
    overflow: visible;
  }
}

.grants-instructions .chapter-contents .--scrollable::-webkit-scrollbar {
  width: 12px;
}

.grants-instructions .chapter-contents .--scrollable::-webkit-scrollbar-track {
  background-color: #cee0f2;
  border-radius: 6px;
}

.grants-instructions .chapter-contents .--scrollable::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #0024cc;
  border-radius: 6px;
}

.grants-form {
  margin: 0 90px;
}

@media only screen and (width <= 1050px) {
  .grants-form {
    margin: 0;
  }
}

.grants-form .intro {
  width: 760px;
  margin: 100px auto 90px;
}

@media only screen and (width <= 1050px) {
  .grants-form .intro {
    width: auto;
  }
}

.grants-form .intro h2 {
  color: var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024cc);
  text-align: center;
  letter-spacing: -4.3px;
  font-family: Marshalls Serif;
  font-size: 86px;
  font-style: italic;
  font-weight: 700;
  line-height: 100%;
}

@media only screen and (width <= 1050px) {
  .grants-form .intro h2 {
    letter-spacing: -1.8px;
    font-size: 36px;
  }
}

.grants-form .intro p {
  color: var(--Black, #000);
  text-align: center;
  letter-spacing: -.36px;
  font-family: Marshalls-Sans-Light;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.grants-form .dja {
  background: #fff;
  border-radius: 12px;
  margin-bottom: 80px;
  padding: 35px 0;
  display: none;
}

.grants-form #sweepsiframe {
  border: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.site-header {
  z-index: 2000;
  background-color: #f4f1ee;
  font-family: Marshalls-Sans-Regular;
  font-size: 1.8rem;
  line-height: normal;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: hidden;
}

@media only screen and (width <= 1200px) {
  .site-header {
    overflow: visible;
  }
}

.site-header:not([data-page="grants"]) .site-header__menu-ul-buttons li {
  display: none;
}

.site-header .menu__toggle {
  background-color: #f4f1ee;
}

.site-header .ctn-main {
  padding: 0;
}

.site-header .ctn-main .site-header__ctn {
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  padding: 45px 40px 24px 43px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1200px) {
  .site-header .ctn-main .site-header__ctn {
    padding: 20px;
  }
}

.site-header .site-header__left {
  justify-content: left;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.site-header .site-header__logo {
  cursor: pointer;
  width: 185px;
  margin-right: 80px;
}

@media only screen and (width <= 1200px) {
  .site-header .site-header__logo {
    margin: 0;
    padding: 0;
  }
}

.site-header .site-header__logo img {
  width: 100%;
  display: block;
}

.site-header .site-header__menu-ul {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  column-gap: 60px;
  list-style: none;
  display: -webkit-flex;
  display: flex;
}

.site-header .site-header__menu-ul li {
  color: #0024cc;
  margin: 0;
  display: inline-block;
}

.site-header .site-header__menu-ul li > a {
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.site-header .site-header__menu-ul li:hover a:after, .site-header .site-header__menu-ul li.active a:after {
  content: "";
  background: #0024cc;
  width: 100%;
  height: 12px;
  position: absolute;
  top: -64px;
  left: 0;
}

@media only screen and (width <= 1200px) {
  .site-header .site-header__menu-ul li:hover a:after, .site-header .site-header__menu-ul li.active a:after {
    display: none;
  }

  .site-header .site-header__menu-ul {
    background-color: #f5f1ee;
    -webkit-flex-direction: column;
    flex-direction: column;
    row-gap: 38px;
    width: 100%;
    padding: 20px;
    display: none;
    position: absolute;
    top: 95px;
    left: 0;
  }
}

.site-header .site-header__menu-ul.open {
  z-index: 100;
  display: -webkit-flex;
  display: flex;
}

.site-header .site-header__right {
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.site-header .site-header__right p {
  text-align: right;
  letter-spacing: -.3px;
  width: 180px;
  margin: 0;
  font-size: 15px;
  line-height: 110%;
}

.site-header .site-header__right .menu__toggle {
  color: #0024cc;
  cursor: pointer;
  border: none;
  padding: 10px;
  display: none;
}

.site-header .site-header__right .menu__toggle .fa:before {
  font-size: 25px;
}

@media only screen and (width <= 1200px) {
  .site-header .site-header__right .menu__toggle {
    display: block;
  }
}

.site-header .site-header__right .menu__toggle.is-visible {
  display: block;
}

.site-header .site-header__right .js-open.is-visible, .site-header .site-header__right .js-close {
  display: none;
}

.site-header .site-header__right .js-close.is-visible {
  display: block;
}

.site-header .site-header__right .menu__item {
  margin-left: 35px;
  position: relative;
}

.site-header .site-header__right .menu__item:before {
  content: "";
  opacity: 0;
  background: url("assets/img/mgssc-banner.svg") center no-repeat;
  width: 1749.61px;
  height: 21px;
  transition: opacity .5s ease-in-out;
  position: absolute;
  top: 8px;
  left: -797px;
}

@media only screen and (width <= 1200px) {
  .site-header .site-header__right .menu__item:before {
    content: "";
    opacity: .5;
    top: 10px;
    -webkit-transform: translateX(470px) !important;
    transform: translateX(470px) !important;
  }
}

.site-header .site-header__right .menu__item:first-child:before {
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

@media only screen and (width <= 1200px) {
  .site-header .site-header__right .menu__item:first-child:before {
    content: "";
    opacity: .5;
    top: 10px;
    -webkit-transform: translateX(250px) !important;
    transform: translateX(250px) !important;
  }
}

.site-header .site-header__right .menu__item:nth-child(2):before {
  background-image: url("assets/img/mgsap-banner.svg");
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

.site-header .site-header__right .menu__item:hover:before {
  opacity: 1;
}

@media only screen and (width <= 1200px) {
  .site-header .site-header__right .menu__item:hover:before {
    opacity: 1;
  }
}

.site-header .site-header__right .menu__item .button {
  z-index: 2;
  text-align: center;
  min-width: 152px;
}

.site-header .site-header__menu-ul-buttons {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  list-style: none;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1200px) {
  .site-header .site-header__menu-ul-buttons {
    display: none;
  }
}

.site-header .site-header__menu-ul-buttons.open {
  z-index: 100;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1200px) {
  .site-header .site-header__menu-ul-buttons.open {
    background-color: #f5f1ee;
    -webkit-flex-direction: column;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    padding: 30px 0 20px;
    position: absolute;
    top: 260px;
    left: 0;
  }

  .site-header .site-header__menu-ul-buttons.open .menu__item:first-child {
    margin-left: 20px;
  }

  .site-header .site-header__menu-ul-buttons.open .menu__item:nth-child(2) {
    align-self: flex-start;
    margin-left: 20px;
  }
}

.hero-banner {
  color: #0024cc;
  background-color: #0024cc;
}

.hero-banner img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (width >= 1051px) {
  .hero-banner img {
    height: auto;
  }
}

.hero-banner .ctn-main.--grid-container {
  background-color: #0024cc;
  grid-template: "title smiling-woman" 1fr
                 "title explore" 1fr
                 / 1fr 1fr;
  padding: 0;
  display: grid;
}

@media only screen and (width <= 1050px) {
  .hero-banner .ctn-main.--grid-container {
    display: block;
  }
}

@media only screen and (width >= 1051px) {
  .hero-banner .ctn-main.--grid-container:hover .--left-section {
    border-radius: 0 0 0 80px;
  }

  .hero-banner .ctn-main.--grid-container:hover .--right-section img {
    border-radius: 0 80px 0 0;
  }
}

.hero-banner .ctn-main.--grid-container h1 {
  margin-bottom: 5px;
}

.hero-banner .ctn-main.--grid-container h6 {
  margin-bottom: 12px;
}

.hero-banner .ctn-main.--grid-container .grid-item {
  position: relative;
}

.hero-banner .ctn-main.--grid-container .grid-item .content-top {
  max-width: 460px;
}

@media only screen and (width <= 1050px) {
  .hero-banner .ctn-main.--grid-container .grid-item .content-top {
    max-width: 100%;
  }
}

.hero-banner .ctn-main.--grid-container .grid-item .cta-box {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-banner .ctn-main.--grid-container .grid-item.--title {
  background-color: #fff;
  border-radius: 0 30%;
  -webkit-flex-direction: column;
  flex-direction: column;
  grid-area: title;
  justify-content: center;
  padding: 120px 76px 40px 80px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .hero-banner .ctn-main.--grid-container .grid-item.--title {
    max-width: 100%;
    padding: 80px 40px 40px;
  }
}

.hero-banner .ctn-main.--grid-container .grid-item.--title h1 {
  max-width: 600px;
}

@media only screen and (width <= 1050px) {
  .hero-banner .ctn-main.--grid-container .grid-item.--title h1 {
    max-width: 100%;
  }
}

.hero-banner .ctn-main.--grid-container .grid-item.--title .button {
  align-self: flex-end;
}

.hero-banner .ctn-main.--grid-container .grid-item.--smiling-woman {
  height: 343px;
}

@media only screen and (width <= 1050px) {
  .hero-banner .ctn-main.--grid-container .grid-item.--smiling-woman {
    height: auto;
  }
}

.hero-banner .ctn-main.--grid-container .grid-item.--explore {
  background-color: #f5f1ee;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  display: -webkit-flex;
  display: flex;
}

.hero-banner .ctn-main.--grid-container .grid-item.--explore h2 {
  margin-bottom: 23px;
}

.hero-banner .ctn-main.--grid-container .grid-item.--explore h6 {
  color: #142a74;
}

.hero-banner .ctn-main.--grid-container .grid-item.--explore .button {
  align-self: flex-end;
}

@media only screen and (width <= 1050px) {
  .hero-banner .ctn-main.--grid-container .grid-item.--explore .button {
    align-self: flex-start;
    margin-top: 48px;
  }
}

.site-footer {
  color: #fff;
  letter-spacing: -.3px;
  background-color: #0024cc;
  font-size: 1.5rem;
  line-height: 15px;
}

.site-footer .ctn-main {
  padding: 40px;
}

@media only screen and (width <= 1050px) {
  .site-footer .ctn-main {
    padding: 20px;
  }
}

.site-footer .site-footer__ctn {
  justify-content: space-between;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .site-footer .site-footer__ctn {
    -webkit-flex-direction: column;
    flex-direction: column;
    margin-top: 30px;
  }
}

.site-footer .site-footer__nav {
  letter-spacing: -.3px;
  margin-top: 16px;
  font-size: 1.5rem;
  line-height: 16.5px;
}

.site-footer .site-footer__nav .bolder {
  letter-spacing: -.36px;
  font-family: Marshalls-Sans-Bold;
  font-size: 1.8rem;
  line-height: 19.8px;
  text-decoration: none;
}

@media only screen and (width <= 1050px) {
  .site-footer .site-footer__nav .bolder {
    letter-spacing: -.32px;
    font-size: 1.6rem;
    line-height: 17.6px;
  }
}

.site-footer .site-footer__nav ul {
  -webkit-flex-direction: column;
  flex-direction: column;
  row-gap: 16px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .site-footer .site-footer__nav ul {
    width: 100%;
  }
}

.site-footer .site-footer__nav ul li {
  display: inline-block;
}

.site-footer .site-footer__nav ul a {
  color: #fff;
  text-decoration: underline;
  transition: color .5s;
}

.site-footer .site-footer__right {
  text-align: right;
}

@media only screen and (width <= 1050px) {
  .site-footer .site-footer__right {
    text-align: left;
    margin-top: 66px;
  }
}

.site-footer .site-footer__right .site-footer__social ul {
  justify-content: flex-end;
  column-gap: 40px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .site-footer .site-footer__right .site-footer__social ul {
    justify-content: flex-start;
  }
}

.site-footer .site-footer__right .site-footer__social a {
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
  padding-top: 1px;
  padding-left: 1px;
  display: -webkit-flex;
  display: flex;
}

.site-footer .site-footer__right .site-footer__social a[name="tiktok"] img {
  max-width: 24px;
}

.site-footer .site-footer__right .site-footer__social img {
  display: block;
}

.site-footer .site-footer__legal {
  text-align: right;
  margin-top: 66px;
}

@media only screen and (width <= 1051px) {
  .site-footer .site-footer__legal {
    text-align: left;
  }
}

.site-footer .site-footer__legal p {
  margin: 0;
  font-size: 15px;
}

.modal {
  display: none;
}

.modal.-active {
  display: block;
}

.modal .video-container > iframe {
  height: 100%;
}

.modal.-portrait .modal__wrapper {
  width: 65%;
  height: 95vh;
}

@media only screen and (width <= 425px) {
  .modal.-portrait .modal__wrapper {
    width: 90%;
  }
}

.modal.-portrait .video-container > iframe {
  height: 85vh;
}

.modal .modal__bg {
  z-index: 3;
  cursor: pointer;
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal .modal__wrapper {
  z-index: 4;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  width: 70vw;
  max-width: 1024px;
  max-height: calc(92vh - 100px);
  margin: 0 auto;
  padding: 70px 40px 40px;
  position: fixed;
  top: calc(50% - 80px);
  left: 50%;
  overflow: auto;
  -webkit-transform: translate(-50%, calc(130px - 50%));
  transform: translate(-50%, calc(130px - 50%));
  box-shadow: 0 0 9px 3px #00000042;
}

@media only screen and (width <= 1000px) {
  .modal .modal__wrapper {
    width: 100%;
    max-height: calc(100vh - 130px);
    padding: 30px 10px 10px;
    top: calc(50% - 80px);
  }
}

.modal .modal__close-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media only screen and (width <= 1000px) {
  .modal .modal__close-btn {
    top: 5px;
    right: 5px;
  }
}

.modal .modal__close-btn:before {
  content: "";
  background-image: url("assets/img/icon-close.svg");
  background-repeat: no-repeat;
  background-size: 25px;
  width: 25px;
  height: 25px;
  transition: background-image .2s;
  display: block;
}

.cookie-bar {
  color: #fff;
  background-color: #0024cc;
  width: 100%;
  padding: 20px 40px;
  font-size: 16px;
  line-height: 120%;
  position: fixed;
  bottom: 0;
}

@media only screen and (width <= 1050px) {
  .cookie-bar {
    padding: 20px;
  }
}

.cookie-bar .ctn-main {
  justify-content: space-between;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .cookie-bar .ctn-main {
    -webkit-flex-direction: column;
    flex-direction: column;
    padding: 0;
  }
}

.cookie-bar p {
  margin: 0;
}

.cookie-bar a {
  color: #fff;
}

.cookie-bar .button-group {
  -webkit-flex-flow: wrap;
  flex-flow: wrap;
  gap: 10px;
  margin-left: 40px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1512px) {
  .cookie-bar .button-group {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

@media only screen and (width <= 1050px) {
  .cookie-bar .button-group {
    -webkit-flex-direction: row;
    flex-direction: row;
    margin: 20px 0;
  }
}

.cookie-bar .button {
  color: #0024cc;
  white-space: nowrap;
  text-align: center;
  background-color: #fff;
  padding: 12px 18px;
  font-size: 14px;
}

body .--cookie-optout, body .--cookie-all {
  display: none;
}

body.--cookie-all .--cookie-all, body.--cookie-optout .--cookie-optout {
  display: block;
}

.two-column {
  background-color: #0024cc;
}

.two-column .ctn-main.--grid-container {
  background-color: #0024cc;
  grid-template-columns: 1fr 1fr;
  padding: 40px;
  display: grid;
}

@media only screen and (width <= 1050px) {
  .two-column .ctn-main.--grid-container {
    padding: 20px;
    display: block;
  }
}

.two-column .ctn-main.--grid-container.true {
  display: block;
}

.two-column .ctn-main.--grid-container.true .--right-section {
  display: none;
}

@media only screen and (width >= 1051px) {
  .two-column .ctn-main.--grid-container.true:hover .--left-section .--anim-wrapper {
    border-radius: 80px 0;
    width: 100%;
  }
}

.two-column .ctn-main.--grid-container h6 {
  margin-bottom: 12px;
}

.two-column .ctn-main.--grid-container h3 {
  max-width: 420px;
  font-family: Marshalls-Sans-Light;
}

.two-column .ctn-main.--grid-container .button {
  max-width: fit-content;
}

.two-column .ctn-main.--grid-container .--left-section .--anim-wrapper {
  z-index: 1;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 80px;
  padding: 40px;
  transition: width 1.5s ease-out, border-radius .2s ease-out .1s, padding-left .5s ease-out, background-color .5s ease-out;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.two-column .ctn-main.--grid-container .--left-section .cta-box {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width >= 1051px) {
  .two-column .ctn-main.--grid-container .--left-section:hover .--anim-wrapper {
    border-radius: 80px 0;
    width: 110%;
    padding-left: 80px;
    transition: width 1.5s ease-out, border-radius .2s ease-out, padding-left .5s ease-out, background-color .5s ease-out;
  }

  .two-column .ctn-main.--grid-container .--left-section:hover + .--right-section .--anim-wrapper {
    padding-left: 110px;
  }

  .two-column .ctn-main.--grid-container .--left-section + .--right-section .--anim-wrapper {
    transition: padding-left .5s ease-out;
  }
}

.two-column .ctn-main.--grid-container .--left-section.color-darker-blue .--anim-wrapper {
  background-color: #142a74;
}

@media only screen and (width >= 1051px) {
  .two-column .ctn-main.--grid-container .--left-section.color-darker-blue:hover .--anim-wrapper {
    background-color: #fdfbfa;
  }

  .two-column .ctn-main.--grid-container .--left-section.color-darker-blue:hover h6, .two-column .ctn-main.--grid-container .--left-section.color-darker-blue:hover h3 {
    color: #0024cc;
  }
}

.two-column .ctn-main.--grid-container .--left-section h6.color-white, .two-column .ctn-main.--grid-container .--left-section h3.color-white {
  color: #fff;
}

.two-column .ctn-main.--grid-container .--right-section {
  display: block;
}

.two-column .ctn-main.--grid-container .--right-section .--anim-wrapper {
  transition: border-radius .2s ease-out .1s, width 1.5s ease-out, background-color .5s ease-out, -webkit-transform .2s, transform .2s, padding-left .5s ease-out;
  position: relative;
}

.two-column .ctn-main.--grid-container .--right-section .--anim-wrapper .inner {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 80px;
  display: -webkit-flex;
  display: flex;
}

.two-column .ctn-main.--grid-container .--right-section .cta-box {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width >= 1051px) {
  .two-column .ctn-main.--grid-container .--right-section:hover .--anim-wrapper {
    z-index: 2;
    border-radius: 0 80px;
    width: 110%;
    transition: border-radius .2s ease-out, width 1.5s ease-out, background-color .5s ease-out;
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
  }

  .two-column .ctn-main.--grid-container .--right-section:hover .inner {
    padding-left: 40px;
    -webkit-transform: translateX(-40px);
    transform: translateX(-40px);
  }

  .two-column .ctn-main.--grid-container .--right-section .inner {
    transition: -webkit-transform .5s ease-out, transform .5s ease-out;
  }
}

.two-column .ctn-main.--grid-container .--right-section.color-lightpurple .--anim-wrapper {
  background-color: #e1cfe6;
}

@media only screen and (width >= 1051px) {
  .two-column .ctn-main.--grid-container .--right-section.color-lightpurple:hover .--anim-wrapper {
    background-color: #cee0f2;
  }
}

.two-column .ctn-main.--grid-container .--right-section h6.color-darker-blue {
  color: #142a74;
}

.two-column .ctn-main.--grid-container .--right-section h3.color-main {
  color: #0024cc;
}

.hero-section {
  background-color: #f4f1ee;
}

.hero-section .mobile-img {
  display: none;
}

.hero-section .desktop-img {
  object-fit: cover;
  object-position: left;
  width: 50%;
  height: 688px;
  display: block;
}

@media only screen and (width <= 1050px) {
  .hero-section .mobile-img {
    display: block;
  }

  .hero-section .desktop-img {
    width: 100%;
    height: auto;
    display: none;
  }
}

@media only screen and (width <= 375px) {
  .hero-section .mobile-img {
    display: block;
  }

  .hero-section .desktop-img {
    display: none;
  }
}

.hero-section .tag-img {
  cursor: pointer;
  width: 155px;
  height: 155px;
  position: absolute;
  bottom: -65px;
  left: calc(50% - 77.5px);
}

@media only screen and (width <= 1050px) {
  .hero-section .tag-img {
    width: 124px;
    height: 124px;
    left: calc(50% - 62px);
  }
}

.hero-section .ctn-main {
  background-color: #f4f1ee;
  padding: 0 40px 40px;
}

@media only screen and (width <= 1050px) {
  .hero-section .ctn-main {
    padding: 0 20px 20px;
  }
}

.hero-section .ctn-main .hero-wrapper {
  justify-content: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

@media only screen and (width <= 1050px) {
  .hero-section .ctn-main .hero-wrapper {
    display: block;
  }
}

.hero-section .ctn-main .hero-right {
  background-image: url("../../../assets/img/mission-heroright.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 70px;
  width: 50%;
  height: 688px;
  padding-top: 181px;
  padding-left: 20px;
  padding-right: 86px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .hero-section .ctn-main .hero-right {
    background-color: #0024cc;
    background-image: none;
    -webkit-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    width: 100%;
    height: auto;
    padding: 40px;
    display: -webkit-flex;
    display: flex;
  }
}

.hero-section .ctn-main .hero-right .top-text {
  margin: 0 0 0 15%;
}

.hero-section .ctn-main .hero-right .top-text p {
  margin: 0;
}

@media only screen and (width <= 1050px) {
  .hero-section .ctn-main .hero-right .top-text {
    align-self: flex-start;
    margin-top: 0;
  }
}

.hero-section .ctn-main .hero-right h1.sub-hero.color-light-purple {
  color: #e1cfe6;
}

.hero-section .ctn-main .hero-right p {
  color: #fff;
  margin: 0 0 121px 211px;
}

@media only screen and (width <= 1050px) {
  .hero-section .ctn-main .hero-right p {
    align-self: flex-end;
    margin: 40px;
  }
}

.desc-section {
  background-color: #f4f1ee;
}

.desc-section .ctn-main {
  background-color: #f4f1ee;
  padding: 40px;
}

@media only screen and (width <= 1050px) {
  .desc-section .ctn-main {
    padding: 20px;
  }
}

.desc-section .ctn-main .desc-wrapper {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.desc-section .ctn-main .desc-wrapper p {
  text-align: center;
  max-width: 756px;
  margin: 16px 0;
}

@media only screen and (width <= 1050px) {
  .desc-section .ctn-main .desc-wrapper {
    margin-top: 40px;
  }
}

.two-col-text-image {
  background-color: #0024cc;
}

.two-col-text-image .ctn-main {
  background: #0024cc;
}

.two-col-text-image .ctn-main:hover .--left-section {
  border-radius: 0 0 0 80px;
}

.two-col-text-image .ctn-main:hover .--right-section img {
  border-radius: 0 80px 0 0;
}

.two-col-text-image .--grid-container {
  align-items: stretch;
  padding: 40px;
  display: -webkit-flex;
  display: flex;
}

.two-col-text-image .--grid-container .mobile-img {
  display: none;
}

.two-col-text-image .--grid-container .desktop-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (width <= 1050px) {
  .two-col-text-image .--grid-container {
    padding: 20px;
    display: block;
  }

  .two-col-text-image .--grid-container .mobile-img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .two-col-text-image .--grid-container .desktop-img {
    display: none;
  }
}

.two-col-text-image h6 {
  color: #142a74;
  margin-bottom: 20px;
}

.two-col-text-image .grid-item {
  max-width: 50%;
  position: relative;
}

.two-col-text-image .grid-item .cta-box {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width <= 1050px) {
  .two-col-text-image .grid-item {
    max-width: none;
  }
}

.two-col-text-image .--left-section, .two-col-text-image .--right-section img {
  transition: border-radius .5s ease-out;
}

.two-col-text-image .--left-section {
  background-color: #e1cfe6;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 0 0;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 80px 40px 40px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .two-col-text-image .--left-section {
    padding: 30px;
  }
}

.two-col-text-image .--left-section .content-top {
  max-width: 558px;
}

.mission-story {
  color: #0024cc;
}

.mission-story img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.mission-story strong {
  font-family: Marshalls-Serif-Bold-Italic;
}

.mission-story .ctn-main {
  background-color: #cee0f2;
  padding: 40px;
}

.mission-story .ctn-main .story-wrapper {
  background-color: #f4f1ee;
  padding: 100px;
}

@media only screen and (width <= 1000px) {
  .mission-story .ctn-main .story-wrapper {
    display: block;
  }
}

.mission-story .ctn-main .story-wrapper h2 {
  letter-spacing: -2.6px;
  font-family: Marshalls-Sans-Light;
  font-size: 5.2rem;
  line-height: normal;
}

.mission-story .ctn-main .story-wrapper h1 {
  letter-spacing: -4.3px;
  font-size: 8.6rem;
  line-height: 86px;
}

.mission-story .ctn-main .story-wrapper h3 {
  letter-spacing: -1.6px;
  font-family: Marshalls-Sans-Light;
  font-size: 3.2rem;
  line-height: normal;
}

.mission-story .ctn-main .story-wrapper p {
  color: #00005c;
  letter-spacing: -.36px;
  font-size: 1.8rem;
  line-height: 19.8px;
}

.mission-story .ctn-main .first-section {
  justify-content: space-around;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.mission-story .ctn-main .first-section .left-text {
  width: 50%;
}

.mission-story .ctn-main .first-section .right-image {
  position: relative;
}

.mission-story .ctn-main .first-section .right-image .ellipse {
  z-index: 1;
  width: 184px;
  height: 252px;
  position: absolute;
  top: -126px;
  left: -92px;
}

.mission-story .ctn-main .first-section .right-image .main-img {
  z-index: 2;
  width: 378px;
  height: auto;
  position: relative;
}

.mission-story .ctn-main .second-section {
  justify-content: space-between;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
}

.mission-story .ctn-main .second-section .left-image {
  margin-left: 100px;
  position: relative;
}

.mission-story .ctn-main .second-section .left-image .ellipse {
  z-index: 1;
  width: 225px;
  height: 208px;
  position: absolute;
  top: 200px;
  left: -112px;
}

.mission-story .ctn-main .second-section .left-image .main-img {
  z-index: 2;
  border-radius: 220px;
  width: 432px;
  height: auto;
  position: relative;
}

.mission-story .ctn-main .second-section .right-text {
  width: 50%;
}

.mission-access .ctn-main {
  background-color: #fff;
  padding: 80px;
}

@media only screen and (width <= 1050px) {
  .mission-access .ctn-main {
    padding: 40px;
  }
}

.mission-access .ctn-main .access-wrapper {
  background-color: #fff;
  justify-content: space-around;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .mission-access .ctn-main .access-wrapper {
    -webkit-flex-direction: column;
    flex-direction: column;
    row-gap: 28px;
  }
}

.mission-access .ctn-main .access-wrapper p.deep-text {
  color: #142a74;
}

.mission-access .ctn-main .left-image {
  -webkit-flex: 1 0 25%;
  flex: 1 0 25%;
  place-self: flex-start;
}

.mission-access .ctn-main .left-image img {
  width: 134px;
  height: 179px;
}

@media only screen and (width >= 426px) {
  .mission-access .ctn-main .left-image img {
    width: 260px;
    height: 347px;
  }
}

@media only screen and (width >= 769px) {
  .mission-access .ctn-main .left-image img {
    width: 342px;
    height: 470px;
  }
}

@media only screen and (width <= 1050px) {
  .mission-access .ctn-main .left-image {
    justify-content: center;
    align-items: flex-end;
    gap: 12px;
    display: -webkit-flex;
    display: flex;
  }

  .mission-access .ctn-main .left-image h2 {
    letter-spacing: -1.8px;
    font-size: 3.6rem;
    line-height: normal;
  }
}

.mission-access .ctn-main .mid-section {
  -webkit-flex: 1 0 35%;
  flex: 1 0 35%;
  align-self: flex-start;
  max-width: 387px;
  padding: 0 20px;
}

@media only screen and (width <= 1050px) {
  .mission-access .ctn-main .mid-section {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

.mission-access .ctn-main .mid-section h2 {
  margin-bottom: 40px;
  line-height: 46px;
}

.mission-access .ctn-main .right-section {
  border-left: 2px solid #0024cc;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 0 30%;
  flex: 1 0 30%;
  justify-content: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .mission-access .ctn-main .right-section {
    border-left: none;
    align-items: flex-start;
  }
}

.mission-access .ctn-main .right-section .inner {
  margin: 20px 80px;
}

@media only screen and (width <= 1050px) {
  .mission-access .ctn-main .right-section .inner {
    margin: 0;
  }

  .mission-access .ctn-main .right-section h2 {
    letter-spacing: -2.2px;
    font-size: 4.4rem;
    line-height: 44px;
  }
}

.mission-access .ctn-main .right-section #chart {
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  display: -webkit-flex;
  display: flex;
}

.mission-access .ctn-main .right-section #chart p {
  margin: 0;
}

.mission-access .ctn-main .right-section .mid {
  border-bottom: 2px solid #0024cc;
  padding-top: 20px;
}

@media only screen and (width <= 1050px) {
  .mission-access .ctn-main .right-section .mid {
    padding: 40px 0 20px;
  }
}

.mission-access .ctn-main .right-section .top {
  border-bottom: 2px solid #0024cc;
}

@media only screen and (width <= 1050px) {
  .mission-access .ctn-main .right-section .top {
    border-top: 2px solid #0024cc;
    padding: 40px 0 20px;
  }
}

.mission-access .ctn-main .right-section .bottom {
  padding-top: 20px;
}

@media only screen and (width <= 1050px) {
  .mission-access .ctn-main .right-section .bottom {
    padding-top: 40px;
  }
}

.mission-cpartners {
  background-color: #f4f1ee;
}

.mission-cpartners .ctn-main {
  background-color: #f4f1ee;
  padding: 80px;
}

@media only screen and (width <= 1050px) {
  .mission-cpartners .ctn-main {
    padding: 40px;
  }
}

.mission-cpartners .ctn-main .cpartners-wrapper {
  background-color: #f4f1ee;
}

.mission-cpartners .title {
  margin-bottom: 48px;
}

.mission-cpartners .title h2 {
  line-height: 46px;
}

.mission-cpartners .content {
  justify-content: space-between;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .mission-cpartners .content {
    display: block;
  }
}

.mission-cpartners .content .left-section {
  -webkit-flex: 1 0 33%;
  flex: 1 0 33%;
  align-self: flex-start;
}

@media only screen and (width <= 1050px) {
  .mission-cpartners .content .left-section {
    margin-bottom: 40px;
  }
}

.mission-cpartners .content .left-section p {
  letter-spacing: -1.1px;
  max-width: 254px;
  margin: 0;
  font-size: 2.2rem;
  line-height: 22px;
}

@media only screen and (width <= 1050px) {
  .mission-cpartners .content .left-section p {
    letter-spacing: -1px;
    max-width: 100%;
    font-size: 2rem;
    line-height: 20px;
  }
}

.mission-cpartners .content .right-section {
  -webkit-flex: 1 0 66%;
  flex: 1 0 66%;
  column-gap: 40px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .mission-cpartners .content .right-section {
    row-gap: 40px;
    padding: 20px;
    display: block;
  }
}

.mission-cpartners .content .right-section .col-1, .mission-cpartners .content .right-section .col-2, .mission-cpartners .content .right-section .col-3 {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .mission-cpartners .content .right-section .col-1, .mission-cpartners .content .right-section .col-2, .mission-cpartners .content .right-section .col-3 {
    row-gap: 12px;
    margin-bottom: 80px;
  }
}

.mission-cpartners .content .right-section .col-1 .top, .mission-cpartners .content .right-section .col-2 .top, .mission-cpartners .content .right-section .col-3 .top {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  display: -webkit-flex;
  display: flex;
}

.mission-cpartners .content .right-section .col-1 .top div, .mission-cpartners .content .right-section .col-2 .top div, .mission-cpartners .content .right-section .col-3 .top div {
  align-items: center;
  height: 80px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .mission-cpartners .content .right-section .col-1 .top div, .mission-cpartners .content .right-section .col-2 .top div, .mission-cpartners .content .right-section .col-3 .top div {
    height: 60px;
  }
}

.mission-cpartners .content .right-section img#girls-inc {
  width: 100px;
  height: 78px;
}

@media only screen and (width <= 767px) {
  .mission-cpartners .content .right-section img#girls-inc {
    width: 80px;
    height: 63px;
  }
}

.mission-cpartners .content .right-section img#mentor {
  width: 194px;
  height: 51px;
}

@media only screen and (width <= 767px) {
  .mission-cpartners .content .right-section img#mentor {
    width: 155px;
    height: 40px;
  }
}

.mission-cpartners .content .right-section img#savvy-ladies {
  width: 187px;
  height: 28px;
}

@media only screen and (width <= 767px) {
  .mission-cpartners .content .right-section img#savvy-ladies {
    width: 150px;
    height: 22px;
  }
}

.mission-cpartners .content .right-section h6 {
  color: #142a74;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  font-family: Marshalls-Sans-Medium;
  font-size: 1.3rem;
  line-height: 13px;
}

.mission-cpartners .content .right-section p {
  color: #142a74;
  border-top: 2px solid #0024cc;
  margin: 0;
  padding: 20px 0;
}

.resource-grid .ctn-main {
  padding-left: 40px;
  padding-right: 40px;
}

.resource-grid .filters {
  border-top: 2px solid #0024cc;
  margin-top: 60px;
  margin-bottom: 60px;
}

@media only screen and (width <= 1050px) {
  .resource-grid .filters {
    border-top: 0;
    margin-bottom: 40px;
    padding-top: 8px;
    padding-bottom: 28px;
  }
}

.resource-grid .filters .filter-toggle {
  color: #00005c;
  letter-spacing: -.36px;
  text-transform: uppercase;
  border-bottom: 1px solid #0024cc;
  padding-bottom: 8px;
  font-size: 18px;
  line-height: 110%;
  position: relative;
}

.resource-grid .filters .filter-toggle:after {
  content: "";
  background: url("assets/img/icon-chevron.svg") center no-repeat;
  width: 24px;
  height: 24px;
  transition: -webkit-transform .25s ease-out, transform .25s ease-out;
  position: absolute;
  bottom: 0;
  right: 12px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.resource-grid .filters .filter-toggle:has( + .-hide):after {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.resource-grid .filters ul {
  justify-content: space-between;
  list-style: none;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .resource-grid .filters ul {
    display: block;
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
}

.resource-grid .filters li {
  border: 0 solid #0024cc;
  padding: 18px 5px 0 25px;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.resource-grid .filters li.active {
  color: #0024cc;
  border-top-width: 8px;
  padding-top: 10px;
}

.resource-grid .filters li:hover {
  color: #0024cc;
}

@media only screen and (width <= 1050px) {
  .resource-grid .filters li {
    background-color: #cee0f2;
    border: none;
    border-radius: 4px;
    margin-bottom: 6px;
    padding: 12px 12px 12px 29px;
  }

  .resource-grid .filters li.active {
    color: #fff;
    background-color: #0024cc;
  }
}

.resource-grid .filters .separator {
  border-left: 2px solid #0024cc;
  max-height: 30px;
  -webkit-transform: none;
  transform: none;
}

@media only screen and (width <= 1050px) {
  .resource-grid .filters .separator {
    display: none;
  }
}

.resource-grid .filter-item {
  cursor: pointer;
  color: #142a74;
  letter-spacing: -.3px;
  text-transform: uppercase;
  margin-left: 0;
  font-size: 1.5rem;
  line-height: 110%;
  transition: color .5s ease-out;
  position: relative;
}

.resource-grid .filter-item:before {
  content: "";
  opacity: 1;
  width: 16.5px;
  height: 16.5px;
  transition: opacity .5s ease-out;
  position: absolute;
  bottom: 1px;
  left: 5px;
}

@media only screen and (width <= 1050px) {
  .resource-grid .filter-item:before {
    top: 11px;
    left: 10px;
  }
}

@media only screen and (width >= 1051px) {
  .resource-grid .filter-item:hover:before, .resource-grid .filter-item.active:before {
    opacity: 0;
  }
}

.resource-grid .filter-item:after {
  content: "";
  opacity: 0;
  width: 16.5px;
  height: 16.5px;
  transition: opacity .5s ease-out;
  position: absolute;
  bottom: 1px;
  left: 5px;
}

@media only screen and (width >= 1051px) {
  .resource-grid .filter-item:hover:after, .resource-grid .filter-item.active:after {
    opacity: 1;
  }
}

.resource-grid .filter-item.active:before {
  top: 9.5px;
}

@media only screen and (width <= 1050px) {
  .resource-grid .filter-item {
    letter-spacing: -.28px;
    padding-left: 10px;
    font-size: 1.4rem;
    line-height: 15.4px;
  }
}

.resource-grid .filter-item.no-icon {
  padding-left: 5px;
}

@media only screen and (width <= 1050px) {
  .resource-grid .filter-item.no-icon {
    padding-left: 10px;
  }
}

.resource-grid .filter-item.no-icon:before {
  display: none;
}

.resource-grid .filter-item.financial-empowerment:before {
  background: url("assets/img/icon-filter-arch.svg") center no-repeat;
}

.resource-grid .filter-item.financial-empowerment:after {
  background: url("assets/img/icon-filter-arch-blue.svg") center no-repeat;
}

.resource-grid .filter-item.embracing-self-worth:before {
  background: url("assets/img/icon-filter-oval.svg") center no-repeat;
}

.resource-grid .filter-item.embracing-self-worth:after {
  background: url("assets/img/icon-filter-oval-blue.svg") center no-repeat;
}

.resource-grid .filter-item.community-connection:before {
  background: url("assets/img/icon-filter-hourglass.svg") center no-repeat;
}

.resource-grid .filter-item.community-connection:after {
  background: url("assets/img/icon-filter-hourglass-blue.svg") center no-repeat;
}

.resource-grid .filter-item.showing-up-confidently:before {
  background: url("assets/img/icon-filter-leaf.svg") center no-repeat;
}

.resource-grid .filter-item.showing-up-confidently:after {
  background: url("assets/img/icon-filter-leaf-blue.svg") center no-repeat;
}

.resource-grid .filter-item.location:before {
  background: url("assets/img/icon-filter-map.svg") center no-repeat;
}

.resource-grid .filter-item.location:after {
  background: url("assets/img/icon-filter-map-blue.svg") center no-repeat;
}

.resource-grid .--grid-container {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-evenly;
  place-items: start center;
  min-height: 660px;
  margin-bottom: 60px;
  display: grid;
}

@media only screen and (width <= 1050px) {
  .resource-grid .--grid-container {
    display: block;
  }
}

.resource-grid .grid-item {
  background-color: #f5f1ee;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 0 0;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  max-width: 777px;
  min-height: 612px;
  padding: 24px 16px;
  transition: background-color .5s ease-out, color .5s ease-out;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.resource-grid .grid-item .--icon.--white {
  display: none;
}

.resource-grid .grid-item .filter-item {
  transition: color .5s ease-out;
}

.resource-grid .grid-item .cta-arrow {
  transition: color .5s ease-out, border-color .5s ease-out;
}

.resource-grid .grid-item .cta-arrow:after {
  opacity: 1;
  background-size: contain;
  transition: opacity .5s ease-out, background .5s ease-out;
}

@media only screen and (width >= 1051px) {
  .resource-grid .grid-item:hover {
    color: #e1cfe6;
    background-color: #0024cc;
  }

  .resource-grid .grid-item:hover .--icon.--white {
    background-color: #0024cc;
    display: block;
  }

  .resource-grid .grid-item:hover .--icon {
    display: none;
  }

  .resource-grid .grid-item:hover .filter-item {
    color: #fff;
  }

  .resource-grid .grid-item:hover .filter-item:after {
    opacity: 1;
  }

  .resource-grid .grid-item:hover .financial-empowerment:after {
    background: url("assets/img/icon-filter-arch-white.svg") center no-repeat;
  }

  .resource-grid .grid-item:hover .embracing-self-worth:after {
    background: url("assets/img/icon-filter-oval-white.svg") center no-repeat;
  }

  .resource-grid .grid-item:hover .community-connection:after {
    background: url("assets/img/icon-filter-hourglass-white.svg") center no-repeat;
  }

  .resource-grid .grid-item:hover .showing-up-confidently:after {
    background: url("assets/img/icon-filter-leaf-white.svg") center no-repeat;
  }

  .resource-grid .grid-item:hover .location:after {
    background: url("assets/img/icon-filter-map-white.svg") center no-repeat;
  }

  .resource-grid .grid-item:hover .cta-arrow {
    color: #e1cfe6;
    border-color: #fff;
  }

  .resource-grid .grid-item:hover .cta-arrow:after {
    opacity: 1;
    background: url("assets/img/icon-arrow-white.svg") center no-repeat;
    transition: background .5s ease-out, opacity .5s ease-out;
  }
}

@media only screen and (width <= 1050px) {
  .resource-grid .grid-item {
    height: auto;
    min-height: auto;
    margin-bottom: 12px;
  }
}

.resource-grid .grid-item.--1-wide {
  border-radius: 40px 40px 0 0;
  grid-column: span 1;
}

.resource-grid .grid-item.--1-wide .--leaf {
  border-radius: 0 40px;
  width: calc(100% + 32px);
  max-width: calc(100% + 32px);
  margin: -24px -16px 24px;
}

@media only screen and (width >= 1051px) {
  .resource-grid .grid-item.--1-wide .--leaf {
    height: 191px;
  }
}

.resource-grid .grid-item.--2-wide {
  border-radius: 80px 80px 0 0;
  grid-column: span 2;
  padding: 24px;
}

.resource-grid .grid-item.--2-wide .--leaf {
  border-radius: 0 80px;
  width: calc(100% + 48px);
  max-width: calc(100% + 48px);
  margin: -24px -24px 24px;
}

@media only screen and (width >= 1051px) {
  .resource-grid .grid-item.--2-wide .--leaf {
    height: 352px;
  }
}

.resource-grid .grid-item h3 {
  letter-spacing: -1.6px;
  -webkit-flex: 1 0 0;
  flex: 1 0 0;
  align-self: stretch;
  margin-top: 12px;
  font-size: 32px;
  line-height: normal;
}

@media only screen and (width <= 1050px) {
  .resource-grid .grid-item h3 {
    letter-spacing: -1.2px;
    margin-bottom: 10px;
    font-size: 24px;
  }
}

.resource-grid .grid-item .cta-arrow {
  letter-spacing: -.36px;
  text-transform: uppercase;
  font-family: Marshalls-Sans-Medium;
  font-size: 18px;
  line-height: normal;
}

.resource-grid .grid-item .cta-arrow p {
  border-bottom: 1px solid #0000;
  margin: 0;
  transition: border-bottom .5s ease-out;
  display: inline-block;
}

.resource-grid .grid-item .cta-arrow:after {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: -webkit-transform .5s ease-out, transform .5s ease-out !important;
}

@media only screen and (width >= 1051px) {
  .resource-grid .grid-item .cta-arrow:hover p {
    border-bottom: 1px solid #fff;
  }

  .resource-grid .grid-item .cta-arrow:hover:after {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}

@media only screen and (width <= 1400px) {
  .resource-grid .grid-item .cta-arrow {
    letter-spacing: -.32px;
    font-size: 16px;
  }
}

.resource-grid .grid-item .filter-item {
  cursor: default;
  padding-left: 20px;
}

.resource-grid .grid-item .filter-item:before, .resource-grid .grid-item .filter-item:after {
  top: 0;
  bottom: 0;
  left: 0;
}

.resource-grid .grid-item .filter-item.no-icon {
  padding-left: 0;
}

.resource-grid .grid-item .filter-item.location {
  display: none;
}

.resource-grid .grid-item img.--leaf {
  object-fit: cover;
  width: 100%;
  max-height: 352px;
  position: relative;
}

.resource-grid .grid-item img.--leaf:after {
  content: "";
  cursor: pointer;
  background: url("assets/img/icon-play.svg") center no-repeat;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  padding: 6px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.resource-grid .footer {
  text-align: center;
  margin-bottom: 60px;
}

.resource-grid .grid-item div:not(.cta-group) a {
  position: relative;
}

.resource-grid .grid-item div:not(.cta-group) a:after {
  content: "";
  cursor: pointer;
  background: #fff url("assets/img/icon-play.svg") center no-repeat;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  transition: background-image .5s ease-out, background-color .5s ease-out;
  position: absolute;
  bottom: 38px;
  right: -5px;
}

.resource-grid .grid-item:hover div:not(.cta-group) a:after {
  background-color: #0024cc;
  background-image: url("assets/img/icon-play-white.svg");
}

.event-listing {
  background-color: #f4f1ee;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 80px;
}

@media only screen and (width <= 1050px) {
  .event-listing {
    padding-top: 0;
    padding-bottom: 40px;
  }
}

.event-listing .ctn-main {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (width <= 1050px) {
  .event-listing .ctn-main {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.event-listing .ctn-main > div {
  justify-content: space-between;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1400px) {
  .event-listing .ctn-main > div {
    display: block;
  }
}

@media only screen and (width <= 1050px) {
  .event-listing .ctn-main > div {
    -webkit-flex-direction: column;
    flex-direction: column;
    gap: 60px;
    display: -webkit-flex;
    display: flex;
  }
}

.event-listing .ctn-main .event-card-container {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 50px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .event-listing .ctn-main .event-card-container {
    width: auto;
    margin: 0 auto;
    display: block;
  }
}

.event-listing .ctn-main .event-card {
  background-color: #e1cfe6;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 20%;
  flex: 20%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  max-width: 444px;
  min-height: 506px;
  padding: 24px 20px;
  transition: background-color .5s ease-out;
  display: -webkit-flex;
  display: flex;
}

.event-listing .ctn-main .event-card .tag {
  transition: color .5s ease-out;
}

.event-listing .ctn-main .event-card .card-body {
  transition: color .5s ease-out, border-top-color .5s ease-out;
}

.event-listing .ctn-main .event-card .sub-head {
  transition: color .5s ease-out;
}

.event-listing .ctn-main .event-card .card-footer {
  transition: color .5s ease-out, border-top-color .5s ease-out;
}

.event-listing .ctn-main .event-card .card-footer .cta-arrow {
  transition: color .5s ease-out, -webkit-text-decoration .5s ease-out, text-decoration .5s ease-out;
}

.event-listing .ctn-main .event-card .card-footer .cta-arrow:after {
  transition: background-image .5s ease-out;
}

.event-listing .ctn-main .event-card:first-child {
  margin-left: 0;
}

@media only screen and (width <= 1050px) {
  .event-listing .ctn-main .event-card:first-child {
    margin: 20px 0;
  }

  .event-listing .ctn-main .event-card {
    width: auto;
    margin: 20px 0;
  }
}

.event-listing .ctn-main .event-card.--disabled .cta-group {
  pointer-events: none;
  opacity: .5;
}

.event-listing .ctn-main .event-card.--disabled .cta-arrow:after {
  display: none;
}

.event-listing .ctn-main .event-card:not(.--disabled):hover {
  background-color: #0024cc;
}

.event-listing .ctn-main .event-card:not(.--disabled):hover .tag {
  color: #e1cfe6;
}

.event-listing .ctn-main .event-card:not(.--disabled):hover .card-header .tag:before {
  background-image: url("assets/img/icon-filter-arch-purple.svg");
  bottom: 0;
}

@media only screen and (width >= 767px) {
  .event-listing .ctn-main .event-card:not(.--disabled):hover .card-header .tag:before {
    bottom: -1px;
  }
}

@media only screen and (width >= 1000px) {
  .event-listing .ctn-main .event-card:not(.--disabled):hover .card-header .tag:before {
    bottom: 2px;
  }
}

.event-listing .ctn-main .event-card:not(.--disabled):hover .card-header .subhead {
  color: #fff;
}

.event-listing .ctn-main .event-card:not(.--disabled):hover h3 {
  color: #e1cfe6;
}

.event-listing .ctn-main .event-card:not(.--disabled):hover .card-body {
  color: #fff;
  border-top-color: #fff;
}

.event-listing .ctn-main .event-card:not(.--disabled):hover .card-body a {
  color: #fff;
  text-decoration: underline;
}

.event-listing .ctn-main .event-card:not(.--disabled):hover .card-footer {
  color: #fff;
  border-top-color: #fff;
}

.event-listing .ctn-main .event-card:not(.--disabled):hover .card-footer .cta-arrow {
  color: #fff;
  text-decoration: underline;
}

.event-listing .ctn-main .event-card:not(.--disabled):hover .card-footer .cta-arrow:after {
  background-image: url("assets/img/icon-arrow-white.svg");
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

.event-listing .ctn-main .event-card .card-header {
  -webkit-flex: 1 0 60%;
  flex: 1 0 60%;
  width: 100%;
}

.event-listing .ctn-main .event-card .card-header .tag {
  padding-left: 25px;
  transition: color .25s ease-out;
  position: relative;
}

.event-listing .ctn-main .event-card .card-header .tag:before {
  content: "";
  background: url("assets/img/icon-filter-arch-blue.svg") center no-repeat;
  width: 16.5px;
  height: 16.5px;
  transition: background-image .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 5px;
}

@media only screen and (width >= 767px) {
  .event-listing .ctn-main .event-card .card-header .tag:before {
    bottom: -1px;
  }
}

@media only screen and (width >= 1000px) {
  .event-listing .ctn-main .event-card .card-header .tag:before {
    bottom: 2px;
  }
}

.event-listing .ctn-main .event-card .card-header img {
  object-fit: cover;
  width: 100%;
  height: 250px;
  margin-top: 20px;
}

.event-listing .ctn-main .event-card .card-header .subhead {
  color: #142a74;
  letter-spacing: -1.1px;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: normal;
  transition: color .25s ease-out;
}

@media only screen and (width <= 1050px) {
  .event-listing .ctn-main .event-card .card-header .subhead {
    letter-spacing: -.9px;
    font-size: 18px;
    line-height: normal;
  }
}

.event-listing .ctn-main .event-card .card-header h3 {
  transition: color .25s ease-out;
}

@media only screen and (width <= 1050px) {
  .event-listing .ctn-main .event-card .card-header h3 {
    letter-spacing: -1.4px;
    font-size: 28px;
    line-height: normal;
  }
}

.event-listing .ctn-main .event-card .card-body {
  color: #142a74;
  border-top: 2px solid #142a74;
  -webkit-flex: 1 0 15%;
  flex: 1 0 15%;
  padding-top: 10px;
  transition: color .25s ease-out, border-color .25s ease-out;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .event-listing .ctn-main .event-card .card-body {
    -webkit-flex-direction: column;
    flex-direction: column;
    gap: 12px;
  }
}

.event-listing .ctn-main .event-card .card-body p {
  margin: 0;
}

.event-listing .ctn-main .event-card .card-body h6 {
  letter-spacing: -1.1px;
  margin-right: 20px;
  font-family: Marshalls-Sans-Light;
  font-size: 22px;
  line-height: normal;
  transition: color .15s ease-out;
}

@media only screen and (width <= 1050px) {
  .event-listing .ctn-main .event-card .card-body h6 {
    letter-spacing: -.9px;
    font-size: 18px;
    line-height: normal;
  }
}

.event-listing .ctn-main .event-card .card-body p {
  transition: color .15s ease-out;
}

@media only screen and (width <= 1050px) {
  .event-listing .ctn-main .event-card .card-body p {
    letter-spacing: -.32px;
    font-size: 16px;
    line-height: 17.6px;
  }
}

.event-listing .ctn-main .event-card .card-footer {
  border-top: 2px solid #142a74;
  -webkit-flex: 1 0 15%;
  flex: 1 0 15%;
  width: 100%;
  padding-top: 10px;
  transition: border-color .35s ease-out;
}

.event-listing .ctn-main .event-card .card-footer .cta-arrow {
  color: #142a74;
  padding-right: 20px;
  font-family: Marshalls-Sans-Medium;
  font-weight: 600;
  transition: color .25s ease-out, -webkit-transform .25s ease-out, transform .25s ease-out;
}

@media only screen and (width <= 320px) {
  .event-listing .ctn-main .event-card .card-footer .cta-arrow {
    max-width: 200px;
  }
}

.event-listing .ctn-main .event-card .card-footer .cta-arrow:after {
  background-image: url("assets/img/icon-arrow-navy.svg");
  transition: background-image .25s ease-out;
}

@media only screen and (width <= 320px) {
  .event-listing .ctn-main .event-card .card-footer .cta-arrow:after {
    right: -40px;
  }
}

.events-detail-text {
  background-color: #f4f1ee;
}

.events-detail-text .--grid-container {
  max-width: 1010px;
  padding: 80px 0;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .events-detail-text .--grid-container {
    width: auto;
    padding: 20px;
    display: block;
  }
}

.events-detail-text sup {
  top: -.5rem;
}

.events-detail-text .grid-item.--left-section {
  width: 50%;
  padding-right: 125px;
}

@media only screen and (width <= 1050px) {
  .events-detail-text .grid-item.--left-section {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
}

.events-detail-text .grid-item.--right-section {
  color: #142a74;
  width: 50%;
  padding-top: 80px;
}

@media only screen and (width <= 1050px) {
  .events-detail-text .grid-item.--right-section {
    width: 100%;
    padding-top: 0;
  }
}

.events-detail-text div#Tock_widget_container > div.TockWidgetWrapper path {
  fill: var(--interactive-primary, #344ef4);
}

.events-detail-text div#Tock_widget_container > div.TockWidgetWrapper .TockWidget-H1 {
  text-transform: uppercase;
}

.events-detail-text div#Tock_widget_container > div.TockWidgetWrapper .TockButton-blue {
  background-color: #0024cc;
}

.events-detail-text div#Tock_widget_container > div.TockWidgetWrapper .TockButton-blue:hover {
  background-color: #142a74;
}

.featured-event {
  background-color: #f4f1ee;
}

.featured-event .ctn-main {
  background: #0024cc;
}

.featured-event .--grid-container {
  background-color: #f5f1ee;
  align-items: stretch;
  padding: 40px;
  transition: background-color .5s ease-out;
  display: -webkit-flex;
  display: flex;
}

.featured-event .--grid-container:hover .--left-section {
  background-color: #cee0f2;
}

.featured-event .--grid-container:hover .event-card {
  background-color: #142a74;
}

@media only screen and (width <= 1050px) {
  .featured-event .--grid-container {
    width: auto;
    padding: 0 20px;
    display: block;
  }
}

.featured-event .grid-item {
  max-width: 50%;
}

@media only screen and (width <= 1050px) {
  .featured-event .grid-item {
    max-width: 100%;
  }
}

.featured-event .--left-section {
  background-color: #e1cfe6;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 0 0;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 40px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .featured-event .--left-section {
    padding: 20px;
  }
}

.featured-event .--left-section img {
  object-fit: cover;
  border-radius: 318px 318px 0 0;
  width: 100%;
  height: 100%;
}

.featured-event .event-card {
  background-color: #0024cc;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  min-height: 506px;
  padding: 80px 40px 40px;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.featured-event .event-card:after {
  content: "";
  background: url("assets/img/gssc-badge.gif") center / 100% 100% no-repeat;
  width: 145px;
  height: 145px;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media only screen and (width <= 1400px) {
  .featured-event .event-card:after {
    width: 74px;
    height: 74px;
  }
}

@media only screen and (width <= 1050px) {
  .featured-event .event-card {
    gap: 18px;
    margin-left: 0;
    padding: 20px;
  }
}

.featured-event .event-card h1 {
  color: #e1cfe6;
  margin-bottom: 60px;
}

@media only screen and (width <= 1050px) {
  .featured-event .event-card h1 {
    margin-bottom: 0;
  }
}

.featured-event .event-card h5 {
  color: #e1cfe6;
}

.featured-event .event-card h3 {
  color: #fff;
  margin-bottom: 30px;
  font-family: Marshalls-Sans-Light;
}

@media only screen and (width <= 1050px) {
  .featured-event .event-card h3 {
    letter-spacing: -1.2px;
    margin-bottom: 18px;
    font-size: 2.4rem;
    line-height: normal;
  }
}

@media only screen and (width <= 320px) {
  .featured-event .event-card h3 {
    max-width: 200px;
  }
}

@media only screen and (width <= 1050px) {
  .featured-event .event-card h4 {
    letter-spacing: -.2px;
    font-size: 1.6rem;
    line-height: normal;
  }

  .featured-event .event-card .card-header {
    max-width: 70%;
  }
}

.featured-event .event-card .card-header .tag {
  margin-bottom: 30px;
  padding-left: 25px;
  position: relative;
}

.featured-event .event-card .card-header .tag:before {
  content: "";
  background: url("assets/img/icon-filter-arch-purple.svg") center no-repeat;
  width: 16.5px;
  height: 16.5px;
  position: absolute;
  bottom: 0;
  left: 5px;
}

@media only screen and (width >= 767px) {
  .featured-event .event-card .card-header .tag:before {
    bottom: -1px;
  }
}

@media only screen and (width >= 1000px) {
  .featured-event .event-card .card-header .tag:before {
    bottom: 2px;
  }
}

.featured-event .event-card .card-body {
  color: #fff;
  letter-spacing: -.36px;
  border-top: 2px solid #fff;
  padding-top: 10px;
  font-size: 18px;
  line-height: 110%;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1050px) {
  .featured-event .event-card .card-body {
    display: block;
  }
}

.featured-event .event-card .card-body h3 {
  margin-right: 80px;
}

.featured-event .event-card .card-body p {
  margin: 0;
}

.featured-event .event-card .card-body a {
  color: #fff;
  text-decoration: underline;
}

.featured-event .event-card .card-footer {
  width: 100%;
}

.featured-event .event-card .card-footer .cta-group {
  border-top: 2px solid #fff;
  padding-top: 10px;
}

.featured-event .event-card .card-footer .cta-arrow {
  color: #fff;
  font-family: Marshalls-Sans-Medium;
}

.featured-event .event-card .card-footer .cta-arrow:after {
  background-image: url("assets/img/icon-arrow-white.svg");
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
}

.featured-event div#Tock_widget_container > div.TockWidgetWrapper path {
  fill: var(--text-inverse, #fff);
}

button, input, select, textarea {
  vertical-align: baseline;
  margin: 0;
  font-size: 100%;
}

.site-search input[type="search"] {
  visibility: hidden;
  float: right;
  width: 1px;
}

.form-unit {
  width: 100%;
  margin-bottom: 13px;
}

.form-unit:after {
  content: "";
  clear: both;
  display: table;
}

select.bits-FormControl, input.bits-FormControl, textarea.bits-FormControl {
  border: 0;
  border-radius: 0;
  height: 38px;
}

textarea.bits-FormControl {
  height: auto;
}

.bits-FormControl.btn-submit {
  color: #fff;
  background-color: #d12a2f;
}

select[disabled] {
  color: gray;
  font-style: italic;
}

select {
  color: #fff;
  background-color: #fff;
  border-color: #cbcbcb;
  border-radius: 0;
  height: 30px;
  font-size: 1.8px;
}

::-webkit-input-placeholder {
  font-style: italic;
}

::placeholder {
  font-style: italic;
}

::-webkit-input-placeholder {
  color: gray;
}

::placeholder {
  color: gray;
}

:-ms-input-placeholder {
  color: gray;
}

input:-moz-placeholder {
  color: gray;
}

:focus::-webkit-input-placeholder {
  color: #0000;
}

#loading {
  background-size: 100px 100px;
  width: 100px;
  height: 100px;
  margin: 50px 50%;
  display: none;
}

form > .ctn-main {
  background-color: #fff;
  padding: 58px 70px;
}

form h4 {
  margin-bottom: 15px;
}

.form-section {
  background-color: #f8f8f8;
}

.form-section .button {
  margin-top: 100px;
  margin-bottom: 76px;
}

@media only screen and (width <= 1000px) {
  .form-section .button {
    margin: 48px 0;
  }

  .form-section .ctn-main {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 30px 20px 0;
  }
}

.form-header .text-ctn p {
  margin-bottom: 0;
}

.question-ctn {
  margin-top: 40px;
  transition: all 2s;
}

@media only screen and (width <= 1000px) {
  .question-ctn {
    margin-top: 10px;
  }
}

.question-ctn h3 {
  margin-bottom: 20px;
}

.question-ctn.required-alert {
  background: #ebc3c3;
  box-shadow: 0 0 0 15px #ebc3c3;
}

.form-header + .question-ctn {
  margin-top: 56px;
}

@media only screen and (width <= 1000px) {
  .form-header + .question-ctn {
    margin-top: 10px;
  }
}

.e_alert-mind p, .e_alert-body p, .e_alert-community p {
  color: #c55555;
}

@media only screen and (width <= 1000px) {
  .form-header + .question-ctn {
    margin-top: 0;
  }
}

.error-404 .content.ctn-main {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 273px);
  display: -webkit-flex;
  display: flex;
}

.terms .content {
  margin-top: 80px;
  margin-bottom: 80px;
}

.faq h3 {
  margin-top: 60px;
}

h3 + .accordion-wrapper {
  margin-top: 20px;
}

.no-wrap {
  white-space: nowrap;
}

.gtm-iframe {
  visibility: hidden;
  display: none;
}

@media print {
  * {
    color: #000 !important;
  }

  body {
    background-color: #fff;
    font-size: 12pt;
  }

  img {
    max-width: 300px;
  }

  .site-content {
    padding-bottom: 300px;
    overflow: visible;
  }

  .box {
    max-width: none !important;
    position: static !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .img-ctn {
    position: static !important;
  }

  .steps .col-1 {
    page-break-inside: avoid !important;
  }

  .steps .acc-title {
    display: none;
  }

  .steps .acc-list {
    display: block !important;
  }

  :after, :before {
    display: none !important;
  }

  .block {
    margin-bottom: 50px !important;
    display: block !important;
  }

  .text-ctn, .col-ctn {
    width: 100% !important;
    display: block !important;
  }

  .content-area + .widget-area, .widget-tide, header, footer {
    display: none !important;
  }

  .site-footer, .print-buttons, .button {
    display: none;
  }
}

body.printing .text-centered {
  text-align: left !important;
}
/*# sourceMappingURL=grants-index.b56bc611.css.map */
